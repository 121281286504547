import { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

type JobSkillsResetButtonProps = {
  title?: string;
  onReset?: () => void;
  disabled?: boolean | null;
}

const JobSkillsResetButtonPropTypes = {
  title: PropTypes.string,
  onReset: PropTypes.func,
  disabled: PropTypes.bool
};

const JobSkillsResetButton = forwardRef<HTMLButtonElement, JobSkillsResetButtonProps>(({
  title,
  onReset,
  disabled
}, ref) => (
  <Box
      p={0.5}
      flexGrow={1}
      display="flex"
      alignItems="flex-end"
      justifyContent="flex-end"
  >
    <Button
        ref={ref}
        color="primary"
        variant="text"
        disabled={disabled || !onReset ? true : undefined}
        onClick={onReset}
    >
      <FormattedMessage id={title || 'hr.job_skills.button.reset'}/>
    </Button>
  </Box>
));

JobSkillsResetButton.displayName = 'JobSkillsResetButton';

JobSkillsResetButton.propTypes = JobSkillsResetButtonPropTypes;

export default memo(JobSkillsResetButton);

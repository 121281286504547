// local imports
import { SkillLevel } from '../models/skill';
import { CourseLevel } from '../models/course';
import {
  // queries
  Maybe, SkillType, JobsMovementOrder, JobCategory, SkillOrCourse, JobRelatedSkillsOrder, MyOpportunitiesSortOrder,
  OpportunitiesSortOrder, TalentFinderSort, AdminJobsSort, AdminEmployeesSort, AdminUsersSort, SortDirection,
  SkillActivityType, SQLSortDirection, AdminReportType,
  JobSearchQuery, EmployeeAdviseesQuery,
  DevPlanEmployeesQuery, TalentEmployeesQuery, CohortQuery, DevPlanProgressesQuery, DevPlanQuery,
  EmployeeProgressQuery, CourseAdvisorSearchQuery, EmployeesSearchQuery, EmployeeDetailsQuery,
  DevPlansQuery, EmployeeDevPlansQuery, EmployeeDevPlanQuery, DevPlanCoursesQuery, DevPlanAdvisorsQuery,
  RedeploymentPlanQuery, RedeploymentEmployeesQuery, RedeploymentEmployeeJobsQuery,
  ChartsAvailability, DASkillCourseMappingQuery, DASkillsDevelopmentQuery, DASkillsInsightsQuery, DASkillsInsightsInDemandQuery,
  OpportunitiesQuery, OpportunityMatchesQuery, OpportunityBookingsQuery,
  OpportunityOwnersQuery, MyOpportunitiesQuery, MyOpportunitiesBrowseQuery, MyOpportunityQuery, DevPlanOpportunitiesQuery
} from './types';

// Concise Data Types

export type JobLookupItem = NonNullable<JobSearchQuery['jobSearch']>['results'][0]

export type TalentEmployeeObject = NonNullable<TalentEmployeesQuery['talentEmployees']>['results'][0] &
  NonNullable<CohortQuery['cohort']>['employees'][0];
export type DevPlanEmployee = NonNullable<DevPlanEmployeesQuery['devplanEmployees']>['results'][0];
export type DevPlanProgress = NonNullable<DevPlanProgressesQuery['devplanProgresses']>['results'][0];
export type DevPlanIndividualEmployee = NonNullable<NonNullable<DevPlanQuery['devplan']>['employee']>;
export type TalentManager = NonNullable<
  NonNullable<TalentEmployeesQuery['talentEmployees']>['results'][0]['manager'] &
  NonNullable<CohortQuery['cohort']>['employees'][0]['manager'] &
  DevPlanIndividualEmployee['manager']
>;
export type DevPlanOwner = NonNullable<CohortQuery['cohort']>['owner'] | NonNullable<DevPlanQuery['devplan']>['owner'];

export type CohortDetails = NonNullable<CohortQuery['cohort']>;

export type SkillWithLevel = Omit<
  NonNullable<NonNullable<TalentEmployeesQuery['talentEmployees']>['results'][0]['skills']>[0] &
  NonNullable<NonNullable<CohortQuery['cohort']>['employees'][0]['skills']>[0] &
  NonNullable<DevPlanIndividualEmployee['skills']>[0],
  'current_level' | 'inferred_level'
> & {
  current_level?: Maybe<SkillLevel> | null;
  inferred_level?: Maybe<SkillLevel> | null;
};
export type SkillWithLevels = NonNullable<
  NonNullable<NonNullable<DevPlanEmployeesQuery['devplanEmployees']>['results'][0]['skills']>[0] &
  NonNullable<NonNullable<DevPlanProgressesQuery['devplanProgresses']>['results'][0]['skills']>[0] &
  NonNullable<NonNullable<EmployeeProgressQuery['employeeProgress']>['skills']>[0]
>;

export const SKILL_ACTIVITY_TYPES: readonly SkillActivityType[] = [
  SkillActivityType.education,
  SkillActivityType.experience,
  SkillActivityType.exposure
] as const;

export type EmployeeAdvisee = NonNullable<EmployeeAdviseesQuery['employeeAdvisees']>['results'][0];

export type CourseAdvisorSearchItem = NonNullable<CourseAdvisorSearchQuery['courseAdvisorSearch']>['results'][0];

export type EmployeeSearchItem = NonNullable<EmployeesSearchQuery['employeesSearch']>['results'][0];
export type TalentEmployeeDetails = NonNullable<EmployeeDetailsQuery['employeeDetails']>;
export type DevPlan = NonNullable<DevPlansQuery['devplans']>['results'][0];
export type DevPlanDetails = NonNullable<DevPlanQuery['devplan']>;
export type EmployeeDevPlan = NonNullable<EmployeeDevPlansQuery['employeeDevplans']>['results'][0];
export type EmployeeDevPlanDetails = NonNullable<EmployeeDevPlanQuery['employeeDevplan']>;
export type EmployeeDevPlanProgress = NonNullable<EmployeeProgressQuery['employeeProgress']>;
export type EmployeeDevPlanCourse = NonNullable<NonNullable<EmployeeProgressQuery['employeeProgress']>['courses']>[0];
export type EmployeeDevPlanAdvisor = NonNullable<NonNullable<EmployeeProgressQuery['employeeProgress']>['advisors']>[0];
export type EmployeeDevPlanOpportunity =
  NonNullable<NonNullable<EmployeeProgressQuery['employeeProgress']>['opportunities']>[0];

export type DevPlanTarget = 'job' | 'opportunity';

export type DevPlanCourse = Omit<NonNullable<DevPlanCoursesQuery['devplanCourses']>['results'][0], 'level'> & {
  level: CourseLevel;
};
export type DevPlanAdvisor = NonNullable<DevPlanAdvisorsQuery['devplanAdvisors']>['results'][0];
export type DevPlanOpportunity = NonNullable<DevPlanOpportunitiesQuery['devplanOpportunities']>['results'][0];
export type DevPlanCompletedCourse =
  NonNullable<NonNullable<DevPlanProgressesQuery['devplanProgresses']>['results'][0]['completed_courses']>[0];
export type DevPlanCompletedAdvisor =
  NonNullable<NonNullable<DevPlanProgressesQuery['devplanProgresses']>['results'][0]['completed_advisors']>[0];
export type DevPlanCompletedOpportunity =
  NonNullable<NonNullable<DevPlanProgressesQuery['devplanProgresses']>['results'][0]['completed_opportunities']>[0];

export type RedeploymentDetails = NonNullable<RedeploymentPlanQuery['redeploymentPlan']>;
export type RedeploymentEmployee = NonNullable<RedeploymentEmployeesQuery['redeploymentEmployees']>['results'][0];
export type RedeploymentEmployeeJob =
  NonNullable<RedeploymentEmployeeJobsQuery['redeploymentEmployeeJobs']>['results'][0];

export const SKILL_TYPES: readonly SkillType[] =
  [SkillType.indemand, SkillType.most_targeted, SkillType.top_skills, SkillType.job_skills] as const;
export const SKILL_TYPES_WITHOUT_INDEMAND: readonly SkillType[] =
  [SkillType.most_targeted, SkillType.top_skills, SkillType.job_skills] as const;
export const SKILL_TYPES_WITHOUT_JOB: readonly SkillType[] =
  [SkillType.indemand, SkillType.most_targeted, SkillType.top_skills] as const;
export const SKILL_TYPES_WITHOUT_JOB_INDEMAND: readonly SkillType[] =
  [SkillType.most_targeted, SkillType.top_skills] as const;
export const SKILL_TYPES_JOB_ONLY: readonly SkillType[] =
  [SkillType.job_skills] as const;
export const JOBS_MOVEMENT_ORDER: readonly JobsMovementOrder[] =
  [JobsMovementOrder.target_count, JobsMovementOrder.movement_count] as const;
export const JOB_CATEGORIES: readonly JobCategory[] =
  [JobCategory.technology, JobCategory.business, JobCategory.corporate] as const;

export enum VelocityType {
  high = 'high',
  low = 'low',
  medium = 'medium'
}
export const EMPLOYEE_VELOCITIES: readonly VelocityType[] =
  [VelocityType.high, VelocityType.medium, VelocityType.low] as const;
export const SKILL_OR_COURSE: readonly SkillOrCourse[] = [SkillOrCourse.skill_id, SkillOrCourse.course_id] as const;
export const JOB_RELATED_SKILLS_ORDERS: readonly JobRelatedSkillsOrder[] =
  [JobRelatedSkillsOrder.low, JobRelatedSkillsOrder.high] as const;
// Dashboard Analytics
export type ChartsAvailabilities = Omit<ChartsAvailability, '__typename'>;
export type DACourseSkill =
  Omit<NonNullable<NonNullable<DASkillCourseMappingQuery['daSkillCourseMapping']>['results'][0]['all']>[0], '__typename'>;
export type DASkillsDevelopmentTopCourse =
  NonNullable<NonNullable<DASkillsDevelopmentQuery['daSkillsDevelopment']>['skills'][0]['top_courses']>[0];
export type DASkillsInsightCounts = NonNullable<DASkillsInsightsQuery['daSkillsInsights']>['results'][0] &
  NonNullable<DASkillsInsightsInDemandQuery['daSkillsInsightsInDemand']>['results'][0];
export const COURSE_COMPLETION_DIRCTION: readonly SQLSortDirection[] = [SQLSortDirection.DESC, SQLSortDirection.ASC] as const;

// Opportunity Marketplace

export const MY_OPPORTUNITIES_ORDER: readonly MyOpportunitiesSortOrder[] = [
  MyOpportunitiesSortOrder.default,
  MyOpportunitiesSortOrder.match_rate,
  MyOpportunitiesSortOrder.growth_rate,
  MyOpportunitiesSortOrder.title,
  MyOpportunitiesSortOrder.owner_name,
  MyOpportunitiesSortOrder.location,
  MyOpportunitiesSortOrder.published_at,
  MyOpportunitiesSortOrder.duration,
  MyOpportunitiesSortOrder.start_date
] as const;
export type MyOpportunity = NonNullable<MyOpportunitiesQuery['myOpportunities']>['results'][0] &
  NonNullable<MyOpportunitiesBrowseQuery['myOpportunitiesBrowse']>['results'][0] &
  NonNullable<MyOpportunityQuery['myOpportunity']>;

export const OPPORTUNITIES_ORDER: readonly OpportunitiesSortOrder[] = [
  OpportunitiesSortOrder.title,
  OpportunitiesSortOrder.location,
  OpportunitiesSortOrder.duration,
  OpportunitiesSortOrder.start_date
] as const;
export type OpportunityPost = NonNullable<OpportunitiesQuery['opportunities']>['results'][0];
export type OpportunityEmployee =
  NonNullable<NonNullable<OpportunityMatchesQuery['opportunityMatches']>['results'][0]['employee']> &
  NonNullable<NonNullable<OpportunityBookingsQuery['opportunityBookings']>['results'][0]['employee']> &
  Pick<
    NonNullable<OpportunityMatchesQuery['opportunityMatches']>['results'][0],
    'match_rate' | 'growth_rate' | 'is_new'
  > & {
    skills: NonNullable<NonNullable<OpportunityMatchesQuery['opportunityMatches']>['results'][0]['employee_skills']> &
      NonNullable<NonNullable<OpportunityBookingsQuery['opportunityBookings']>['results'][0]['employee_skills']>;
  } & {
    is_applicant?: boolean;
    is_shortlisted?: boolean;
  };
export type OpportunityOwner = NonNullable<OpportunityOwnersQuery['opportunityOwners']>['results'][0];

export const DEFAULT_OPPORTUNITIES_DIRECTION: Record<
  OpportunitiesSortOrder | MyOpportunitiesSortOrder, boolean
> = {
  [MyOpportunitiesSortOrder.default]: true,
  [MyOpportunitiesSortOrder.title]: true,
  [MyOpportunitiesSortOrder.owner_name]: true,
  [MyOpportunitiesSortOrder.location]: true,
  [MyOpportunitiesSortOrder.published_at]: false,
  [MyOpportunitiesSortOrder.duration]: true,
  [MyOpportunitiesSortOrder.start_date]: true,
  [MyOpportunitiesSortOrder.match_rate]: false,
  [MyOpportunitiesSortOrder.growth_rate]: false
} as const;

// Admin - Jobs
export const ADMIN_JOBS_SORT: readonly AdminJobsSort[] = [
  AdminJobsSort.title,
  AdminJobsSort.location,
  AdminJobsSort.management_level,
  AdminJobsSort.career_ladder,
  AdminJobsSort.org,
  AdminJobsSort.open_req_count,
  AdminJobsSort.employee_count,
  AdminJobsSort.skill_count
] as const;
export const DEFAULT_ADMIN_JOBS_DIRECTION: Record<
  AdminJobsSort, SortDirection
> = {
  [AdminJobsSort.id]: SortDirection.ascending,
  [AdminJobsSort.title]: SortDirection.ascending,
  [AdminJobsSort.org]: SortDirection.ascending,
  [AdminJobsSort.location]: SortDirection.ascending,
  [AdminJobsSort.state]: SortDirection.ascending,
  [AdminJobsSort.country]: SortDirection.ascending,
  [AdminJobsSort.career_ladder]: SortDirection.ascending,
  [AdminJobsSort.management_level]: SortDirection.descending,
  [AdminJobsSort.open_req_count]: SortDirection.descending,
  [AdminJobsSort.new_req_count]: SortDirection.descending,
  [AdminJobsSort.employee_count]: SortDirection.descending,
  [AdminJobsSort.skill_count]: SortDirection.descending
} as const;

// Admin - Employees

export const EmployeeSortExt = { ...TalentFinderSort, ...AdminEmployeesSort };
export type TEmployeeSortExt = TalentFinderSort | AdminEmployeesSort;

export const ADMIN_EMPLOYEES_SORT: readonly AdminEmployeesSort[] = [
  AdminEmployeesSort.full_name,
  AdminEmployeesSort.code,
  AdminEmployeesSort.location,
  AdminEmployeesSort.current_job,
  AdminEmployeesSort.management_level,
  AdminEmployeesSort.org,
  AdminEmployeesSort.manager,
  AdminEmployeesSort.years_on_job,
  AdminEmployeesSort.performance_rating,
  AdminEmployeesSort.skill_count
] as const;
export const DEFAULT_ADMIN_EMPLOYEES_DIRECTION: Record<
  AdminEmployeesSort, SortDirection
> = {
  [AdminEmployeesSort.id]: SortDirection.ascending,
  [AdminEmployeesSort.code]: SortDirection.ascending,
  [AdminEmployeesSort.full_name]: SortDirection.ascending,
  [AdminEmployeesSort.location]: SortDirection.ascending,
  [AdminEmployeesSort.country]: SortDirection.ascending,
  [AdminEmployeesSort.current_job]: SortDirection.ascending,
  [AdminEmployeesSort.management_level]: SortDirection.descending,
  [AdminEmployeesSort.org]: SortDirection.ascending,
  [AdminEmployeesSort.manager]: SortDirection.ascending,
  [AdminEmployeesSort.years_on_job]: SortDirection.descending,
  [AdminEmployeesSort.performance_rating]: SortDirection.descending,
  [AdminEmployeesSort.skill_count]: SortDirection.descending
} as const;

// Admin - Users
export const ADMIN_USERS_SORT: readonly AdminUsersSort[] = [
  AdminUsersSort.id,
  AdminUsersSort.username,
  AdminUsersSort.last_name,
  AdminUsersSort.email,
  AdminUsersSort.is_active,
  AdminUsersSort.date_joined
] as const;
export const DEFAULT_ADMIN_USERS_DIRECTION: Record<
AdminUsersSort, SortDirection
> = {
  [AdminUsersSort.id]: SortDirection.ascending,
  [AdminUsersSort.username]: SortDirection.ascending,
  [AdminUsersSort.last_name]: SortDirection.ascending,
  [AdminUsersSort.email]: SortDirection.ascending,
  [AdminUsersSort.is_active]: SortDirection.descending,
  [AdminUsersSort.date_joined]: SortDirection.descending
} as const;

// Admin - Reports
export const ADMIN_REPORT_TYPES: readonly AdminReportType[] = [
  AdminReportType.user_engagement,
  AdminReportType.job_trends
] as const;

/* eslint-disable max-lines */
import {
  memo, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState,
  type FunctionComponent, type MouseEvent, type ReactNode
} from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import omit from 'lodash/omit';
import find from 'lodash/find';
import includes from 'lodash/includes';
import findIndex from 'lodash/findIndex';
import transform from 'lodash/transform';
import reject from 'lodash/reject';
import uniqBy from 'lodash/uniqBy';
import toUpper from 'lodash/toUpper';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import isSafeInteger from 'lodash/isSafeInteger';
import { DateTime, type ToISOTimeOptions } from 'luxon';
import zod from 'zod';
import { useForm, Controller, type SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import { type SelectChangeEvent } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
// EmPath UI Components
import { fontWeightMedium } from '@empathco/ui-components/src/styles/themeOptions';
import { type GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import { type FormatMessageFuncValues } from '@empathco/ui-components/src/helpers/intl';
import { getUtcFromISO } from '@empathco/ui-components/src/helpers/datetime';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import useConfirmationDialog from '@empathco/ui-components/src/hooks/useConfirmationDialog';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import GridBox from '@empathco/ui-components/src/mixins/GridBox';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import CardFooter from '@empathco/ui-components/src/elements/CardFooter';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
// import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import CheckboxButton from '@empathco/ui-components/src/elements/CheckboxButton';
import FilterSelector from '@empathco/ui-components/src/elements/FilterSelector';
import YesNoButtonGroup from '@empathco/ui-components/src/elements/YesNoButtonGroup';
import DatePicker from '@empathco/ui-components/src/elements/DatePicker';
import PlusButton from '@empathco/ui-components/src/elements/PlusButton';
import ConfirmDialog from '@empathco/ui-components/src/elements/ConfirmDialog';
// local imports
import { COUNTRIES_QUERY } from '../graphql/Countries';
import { NEW_OPPORTUNITY } from '../graphql/NewOpportunity';
import { UPDATE_OPPORTUNITY } from '../graphql/UpdateOpportunity';
import { DELETE_OPPORTUNITY } from '../graphql/DeleteOpportunity';
import {
  LookupItem, OpportunityStatus, OpportunitySkillStatus, Opportunity, Location, OpportunityDurationUnit,
  CountriesDocument,
  NewOpportunityDocument, NewOpportunityMutation, NewOpportunityMutationVariables,
  UpdateOpportunityDocument, UpdateOpportunityMutationVariables, DeleteOpportunityDocument
} from '../graphql/types';
import { ILookupSkill } from '../models/lookupItem';
import { Skill, SkillLevel, SKILL_LEVEL_FIRST, SKILL_LEVEL_MAX } from '../models/skill';
import { Manager } from '../models/manager';
import { getLeaderId } from '../models/user';
import { CONST_HOURS_OPPORTINUTY, CONST_MONTHS_OPPORTINUTY, CONST_TIMEZONES } from '../constants/constValues';
import { TIMEZONE_BY_STATE } from '../constants/timezones';
import { MAX_COUNTRIES_OPTIONS } from '../config/params';
import { PATH_SUPERVISOR_OPPORTUNITIES, PATH_SUPERVISOR_OPPORTUNITY } from '../config/paths';
import { getSkillCurrentLevel, sanitizeLookup } from '../helpers/models';
import { SkillGroup } from '../context/persistent';
import { GlobalContext } from '../context/global';
import { SupervisorContext } from '../context/supervisor';
import ConstSelector from '../elements/ConstSelector';
import SkillChip from '../elements/SkillChip';
import AddGrowthButton from '../elements/AddGrowthButton';
import LocationSearch from '../v3/LocationSearch';
import ChainOfCommandSelector from '../v3/ChainOfCommandSelector';
import AddSkillsDialog from '../widgets/AddSkillsDialog';
import SkillLevelDialog from '../widgets/SkillLevelDialog';
// SCSS imports
import { checkbox } from './OpportunityEditorPanel.module.scss';

const NO_MANAGERS = [] as Manager[];

const dateTimeOptions: ToISOTimeOptions = { includeOffset: false };

type OpportunityEditorPanelProps = {
  opportunityId?: number | null;
  opportunity?: Opportunity | null;
  pending?: boolean | null;
  failed?: boolean | null;
  // for Storybook only
  testAction?: boolean | null;
}

const OpportunityEditorPanelPropsTypes = {
  opportunityId: PropTypes.number,
  opportunity: PropTypes.object as Validator<Opportunity | null>,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  testAction: PropTypes.bool
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const OpportunityEditorPanel: FunctionComponent<OpportunityEditorPanelProps> = ({
  opportunityId,
  opportunity,
  pending,
  failed,
  testAction
}) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { user: { data: user } } = useContext(GlobalContext);
  const { location: userLocation } = user || {};

  const {
    hierarchy: { data: hierarchy, pending: hierarchyPending, failed: hierarchyFailed }, requireHierarchy
  } = useContext(SupervisorContext);
  const managers = (hierarchyFailed && NO_MANAGERS) || (!hierarchyPending && hierarchy) || null;

  // lazy load countires
  const { query: getCountries, pending: pendingCountries, failed: failedCountries, results: countriesData } = useQueryCounted({
    data: undefined as unknown as LookupItem,
    key: 'countries',
    lazyQuery: useLazyQuery(COUNTRIES_QUERY as typeof CountriesDocument)
  });
  const countries = pendingCountries ? null : countriesData;

  const newOpportunity = useMutationMethod({
    // TODO: key: 'newOpportunity', -- restore when backend responds with `success: true`
    mutation: useMutation(NEW_OPPORTUNITY as typeof NewOpportunityDocument)
  });
  const updateOpportunity = useMutationMethod({
    // TODO: key: 'updateOpportunity', -- restore when backend responds with `success: true`
    mutation: useMutation(UPDATE_OPPORTUNITY as typeof UpdateOpportunityDocument)
  });
  const { mutate: postOpportunity, loading: newPending, failed: newFailed } = opportunityId
    ? updateOpportunity : newOpportunity;

  const { mutate: deleteOpportunity, loading: deletePending, failed: deleteFailed } = useMutationMethod({
    mutation: useMutation(DELETE_OPPORTUNITY as typeof DeleteOpportunityDocument)
  });

  useEffect(() => {
    getCountries?.({ variables: { limit: MAX_COUNTRIES_OPTIONS } });
  }, [getCountries]);

  useEffect(() => {
    requireHierarchy?.();
  }, [requireHierarchy]);

  const [anchorAddBtn, setAnchorAddBtn] = useState<HTMLButtonElement | null>(null);
  const [forGrowth, setForGrowth] = useState(false);

  const [location, setLocation] = useState<Location | null>(opportunity?.location || userLocation || null);
  useLayoutEffect(() => {
    setLocation(opportunity?.location || userLocation || null);
  }, [opportunity?.location, userLocation]);

  const [open, setOpen] = useState(false);
  const [skl, setSkl] = useState<Skill | null>(null);
  const handleUpdateClose = useCallback(() => setOpen(false), []);
  const handleUpdateExited = useCallback(() => setSkl(null), []);

  const handleAddRequiredOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    if (event) {
      setForGrowth(false);
      setAnchorAddBtn(event.currentTarget);
    }
  }, []);
  const handleAddGrowthOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    if (event) {
      setForGrowth(true);
      setAnchorAddBtn(event.currentTarget);
    }
  }, []);
  const handleAddClose = useCallback(() => setAnchorAddBtn(null), []);

  const disabled = (opportunityId && pending) || newPending || deletePending || failedCountries || hierarchyPending ||
    (opportunity && opportunity.status !== OpportunityStatus.draft && opportunity.status !== OpportunityStatus.published)
    ? true : undefined;

  const maxDate = useMemo(() => DateTime.utc().plus({ years: 1 }), []);

  const defaultLeader = useMemo(() => getLeaderId(user), [user]);

  const schema = useMemo(() => zod.object({
    title: zod.string().min(1, { message: formatMessage({ id: 'opportunities.input.title.required' }) }),
    country_id: zod.number().int()
      .min(1, { message: formatMessage({ id: 'opportunities.input.country.required' }) }),
    location_id: zod.number().int()
      .min(1, { message: formatMessage({ id: 'opportunities.input.location.required' }) }),
    duration_value: zod.number().int()
      .min(1, { message: formatMessage({ id: 'opportunities.input.duration.required' }) }),
    start_date: zod.string().datetime()
      .optional(),
    scope_manager_id: zod.string(),
    remote: zod.boolean(),
    fulltime: zod.boolean(),
    timezone_required: zod.boolean(),
    timezone: zod.number().int(),
    description: zod.string().min(1, { message: formatMessage({ id: 'opportunities.input.description.required' }) }),
    skills_required: zod.array(zod.object({
      id: zod.number().int()
        .min(1, { message: formatMessage({ id: 'opportunities.input.skills_required.required' }) }),
      level: zod.number().int()
        .min(SKILL_LEVEL_FIRST, { message: formatMessage({ id: 'opportunities.input.skills_required.required' }) })
        .max(SKILL_LEVEL_MAX, { message: formatMessage({ id: 'opportunities.input.skills_required.required' }) }),
      title: zod.string()
    })).min(1, { message: formatMessage({ id: 'opportunities.input.skills_required.required' }) }),
    skills_growth: zod.array(zod.object({
      id: zod.number().int()
        .min(1),
      level: zod.number().int()
        .min(SKILL_LEVEL_FIRST)
        .max(SKILL_LEVEL_MAX),
      title: zod.string()
    })).optional()
  }).superRefine(({ skills_required, skills_growth }, { addIssue }) => {
    const sk = find(skills_growth, ({ id, level }) => {
      const reqSkl = find(skills_required, ['id', id]);
      return Boolean(reqSkl && reqSkl.level >= level);
    });
    if (sk) addIssue({
      code: zod.ZodIssueCode.custom,
      message: formatMessage(
        { id: 'opportunities.input.skills_growth.error' },
        { title: (
          <Box fontWeight={fontWeightMedium} component="span">
            {sk.title}
          </Box>
        )} as unknown as FormatMessageFuncValues // `formatMessage` is string-typed, but actually it supports JSX.Element
      ) as string,
      path: ['skills_growth']
    });
  }), [formatMessage]);

  type Schema = zod.infer<typeof schema>;

  const [skillsRequired, setSkillsRequired] = useState<Skill[]>([]);
  const [skillsGrowth, setSkillsGrowth] = useState<Skill[]>([]);

  const defaultSkillsRequired = useMemo(() =>
    map(skillsRequired, ({ id, title, current_level }) => ({ id, title, level: current_level as SkillLevel })),
    [skillsRequired]
  );
  const defaultSkillsGrowth = useMemo(() =>
    map(skillsGrowth, ({ id, title, current_level }) => ({ id, title, level: current_level as SkillLevel })),
    [skillsGrowth]
  );

  const formParams = useMemo(() => ({
    resolver: zodResolver(schema),
    defaultValues: {
      title: opportunity?.title || '',
      country_id: sanitizeLookup(
        opportunity?.location?.country_id || userLocation?.country_id || location?.country_id || 0,
        countries),
      location_id: opportunity?.location?.id || userLocation?.id || location?.id || 0,
      duration_value: opportunity?.duration_value || (opportunity?.sidegig
        ? CONST_HOURS_OPPORTINUTY[0] : CONST_MONTHS_OPPORTINUTY[2]),
      start_date: opportunity?.start_date || undefined,
      scope_manager_id: opportunity?.scope_manager_id || '0',
      remote: !opportunity?.onsite,
      fulltime: !opportunity?.sidegig,
      timezone_required: !isNil(opportunity?.timezone_minutes),
      timezone: isSafeInteger(opportunity?.timezone_minutes) && includes(CONST_TIMEZONES, opportunity?.timezone_minutes)
        ? opportunity?.timezone_minutes : CONST_TIMEZONES[0],
      description: opportunity?.description || '',
      skills_required: defaultSkillsRequired,
      skills_growth: defaultSkillsGrowth
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  } as Parameters<typeof useForm<Schema>>[0]), [
    // ignoring `location`, `defaultSkillsRequired` and `defaultSkillsGrowth` changes:
    schema, opportunity, userLocation, countries
  ]);

  const defaultManager = (formParams?.defaultValues as Schema).scope_manager_id;

  useLayoutEffect(() => {
    if (opportunity) {
      const [requiredSkls, growthSkls] = transform(opportunity.skills, ([required, growth], {
        id, status, skill_proficiency_level, abbr, title
      }) => {
        (status === OpportunitySkillStatus.required ? required : growth).push({
          id,
          title,
          abbr,
          current_level: skill_proficiency_level as SkillLevel
        });
      }, [[], []] as Skill[][]);
      setSkillsRequired(requiredSkls);
      setSkillsGrowth(growthSkls);
    }
  }, [opportunity]);

  const { control, watch, setValue, clearErrors, trigger, reset, handleSubmit } = useForm<Schema>(formParams);

  useLayoutEffect(() => {
    reset(formParams?.defaultValues as Schema);
  }, [formParams, reset]);

  useLayoutEffect(() => {
    setValue('skills_required', defaultSkillsRequired);
    setValue('skills_growth', defaultSkillsGrowth);
    (opportunityId && !pending ? trigger : clearErrors)(['skills_required', 'skills_growth']);
  }, [defaultSkillsRequired, defaultSkillsGrowth, pending, opportunityId, setValue, clearErrors, trigger]);

  useLayoutEffect(() => {
    setValue('location_id', location?.id || 0);
    (opportunityId && !pending ? trigger : clearErrors)('location_id');
  }, [location, pending, opportunityId, setValue, clearErrors, trigger]);

  const countryId = watch('country_id');
  const isFulltime = watch('fulltime');
  const isTimezoneRequired = watch('timezone_required');

  useLayoutEffect(() => {
    setLocation((prevLocation) => prevLocation?.country_id === countryId ? prevLocation : null);
  }, [countryId]);

  useLayoutEffect(() => {
    const { fulltime, duration_value } = (formParams?.defaultValues || {}) as Schema;
    setValue(
      'duration_value',
      ((isNil(fulltime) || isNil(duration_value) || fulltime !== isFulltime) && (
        isFulltime ? CONST_MONTHS_OPPORTINUTY[2] : CONST_HOURS_OPPORTINUTY[0])
      ) || duration_value
    );
  }, [isFulltime, formParams, setValue]);

  useEffect(() => {
    if (isTimezoneRequired) {
      if (location?.state) {
        const tzOffset = TIMEZONE_BY_STATE[toUpper(location.state)];
        if (isSafeInteger(tzOffset) && includes(CONST_TIMEZONES, tzOffset)) {
          setValue('timezone', tzOffset);
          return;
        }
      }
      const dt = DateTime.local(DateTime.local().year, 1, 1); // TODO: for south latitude winter time is in July, not January
      if (includes(CONST_TIMEZONES, dt.offset)) setValue('timezone', dt.offset);
    }
  }, [isTimezoneRequired, location, setValue]);

  const onSubmit: SubmitHandler<Schema> = useCallback((formData: Schema) => {
    postOpportunity?.({
      variables: {
        ...opportunityId ? { opportunity_id: opportunityId } : {},
        input: {
          ...omit(formData, [
            'start_date', 'timezone_required', 'timezone', 'scope_manager_id',
            'fulltime', 'remote',
            'skills_required', 'skills_growth'
          ]),
          ...opportunityId ? {} : { status: OpportunityStatus.draft },
          duration_unit: formData.fulltime ? OpportunityDurationUnit.month : OpportunityDurationUnit.hour,
          start_date: formData.start_date || null,
          scope_manager_id: !formData.scope_manager_id || formData.scope_manager_id === '0' ? null : formData.scope_manager_id,
          sidegig: !formData.fulltime,
          onsite: !formData.remote,
          timezone_minutes: !formData.timezone_required || isNil(formData.timezone) ? null : formData.timezone,
          skills: [
            ...map(formData.skills_required, ({ id, level }) => ({
              skill_id: id,
              skill_proficiency_level: level,
              status: OpportunitySkillStatus.required
            })),
            ...map(formData.skills_growth || [], ({ id, level }) => ({
              skill_id: id,
              skill_proficiency_level: level,
              status: OpportunitySkillStatus.growth
            }))
          ]
        }
      } as UpdateOpportunityMutationVariables & NewOpportunityMutationVariables,
      // TODO: optimistic response
      update: (cache) => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunities' });
        if (opportunityId) {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunity' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunityMatches' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunityBookings' });
        }
      },
      onCompleted: (data) => {
        const opp_id = opportunityId || (data as NewOpportunityMutation)?.newOpportunity?.id;
        if (opp_id) navigate(injectParams(PATH_SUPERVISOR_OPPORTUNITY, { opp_id }));
      }
    });
  }, [postOpportunity, opportunityId, navigate]);

  const onDelete = useCallback(() => {
    if (opportunityId) deleteOpportunity?.({
      variables: { opportunity_id: opportunityId },
      update: (cache) => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunities' });
      },
      onCompleted: () => navigate(PATH_SUPERVISOR_OPPORTUNITIES)
    });
  }, [opportunityId, deleteOpportunity, navigate]);

  const {
    confirmOpen,
    confirmMounted,
    handleAction: handleDelete,
    handleCancel,
    handleExited,
    handleConfirm
  } = useConfirmationDialog(onDelete);

  const [titleLabel, startDateLabel, descriptionLabel] = useMemo(() => [
    formatMessage({ id: 'opportunities.input.title' }),
    formatMessage({ id: 'opportunities.input.start_date' }),
    formatMessage({ id: 'opportunities.input.description' })
  ], [formatMessage]);

  const renderTitleField = useCallback(({
    field, fieldState: { invalid, error }
  }: Parameters<GetComponentProps<typeof Controller<Schema, 'title'>>['render']>[0]) => (
    <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        variant="outlined"
        margin="dense"
        size="small"
        fullWidth
        label={titleLabel}
        error={invalid}
        helperText={error?.message || '\u00A0'}
        disabled={disabled}
    />
  ), [disabled, titleLabel]);

  const renderCountryField = useCallback(({
    field, fieldState: { invalid, error }
  }: Parameters<GetComponentProps<typeof Controller<Schema, 'country_id'>>['render']>[0]) => (
    <FilterSelector
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        type="country"
        required
        choices={countries}
        error={invalid}
        helperText={error?.message || '\u00A0'}
        disabled={disabled}
    />
  ), [countries, disabled]);

  const renderLocationField = useCallback(({
    field, fieldState: { invalid, error }
  }: Parameters<GetComponentProps<typeof Controller<Schema, 'location_id'>>['render']>[0]) => (
    <LocationSearch
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        countryId={countryId}
        value={countryId ? location : null}
        onChange={setLocation}
        disabled={disabled || !countryId}
        error={invalid}
        helperText={error?.message || '\u00A0'}
    />
  ), [disabled, location, countryId]);

  const renderDurationField = useCallback(({
    field, fieldState: { invalid, error }
  }: Parameters<GetComponentProps<typeof Controller<Schema, 'duration_value'>>['render']>[0]) => (
    <ConstSelector
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...omit(field, 'onChange')}
        onChange={field.onChange as (event: SelectChangeEvent<number>, child?: ReactNode) => void}
        variant={isFulltime ? 'opportunityMonths' : 'opportunityHours'}
        fullWidth
        error={invalid}
        helperText={error?.message || '\u00A0'}
        disabled={disabled}
    />
  ), [isFulltime, disabled]);

  const renderScopeManagerField = useCallback(({
    field, fieldState: { invalid, error }
  }: Parameters<GetComponentProps<typeof Controller<Schema, 'scope_manager_id'>>['render']>[0]) => (
    <ChainOfCommandSelector
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        uid={defaultManager}
        me={defaultLeader}
        expandable
        managers={managers}
        error={invalid}
        helperText={error?.message || formatMessage({ id: 'opportunities.input.scope_manager' })}
        disabled={disabled}
    />
  ), [defaultManager, defaultLeader, managers, disabled, formatMessage]);

  const renderTimezoneRequiredField = useCallback((
    { field }: Parameters<GetComponentProps<typeof Controller<Schema, 'timezone_required'>>['render']>[0]
  ) => (
    <CheckboxButton
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        label="opportunities.input.timezone_required"
        checked={field.value}
        disabled={disabled}
        className={checkbox}
    />
  ), [disabled]);

  const renderTimezoneField = useCallback(({
    field, fieldState: { invalid, error }
  }: Parameters<GetComponentProps<typeof Controller<Schema, 'timezone'>>['render']>[0]) => (
    <ConstSelector
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...omit(field, 'onChange')}
        onChange={field.onChange as (event: SelectChangeEvent<number>, child?: ReactNode) => void}
        variant="timezones"
        fullWidth
        error={invalid}
        helperText={error?.message || '\u00A0'}
        disabled={disabled}
    />
  ), [disabled]);

  const renderBooleanField = useCallback(({ field }: Parameters<GetComponentProps<
    typeof Controller<Schema, 'fulltime'> |
    typeof Controller<Schema, 'remote'>
  >['render']>[0]) => (
    <YesNoButtonGroup
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        small
        yesLabel={`opportunities.input.${field.name}.true`}
        noLabel={`opportunities.input.${field.name}.false`}
        disabled={disabled}
    />
  ), [disabled]);

  type DateOnChange = Parameters<GetComponentProps<typeof Controller<Schema, 'start_date'>>['render']>[0]['field']['onChange'];
  const dateOnChangeRef = useRef<{
    onChange?: DateOnChange,
    ourOnChange?: (date?: DateTime | null) => void
  }>();
  const renderStartDateField = useCallback(({
    field, fieldState: { invalid, error }
  }: Parameters<GetComponentProps<typeof Controller<Schema, 'start_date'>>['render']>[0]) => {
    if (!dateOnChangeRef.current) dateOnChangeRef.current = {};
    if (isNil(dateOnChangeRef.current.onChange) || dateOnChangeRef.current.onChange !== field.onChange) {
      dateOnChangeRef.current.onChange = field.onChange;
      dateOnChangeRef.current.ourOnChange =
        (date?: DateTime | null) => field.onChange((date?.isValid && `${date.toISO(dateTimeOptions)}Z`) || undefined);
    }
    return (
      <DatePicker
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...field}
          placeholder={startDateLabel}
          error={invalid}
          helperText={error?.message || '\u00A0'}
          value={field.value ? getUtcFromISO(field.value) : undefined}
          onChange={dateOnChangeRef.current?.ourOnChange}
          disabled={disabled}
          disablePast
          maxDate={maxDate}
      />
    );
  }, [disabled, maxDate, startDateLabel]);

  const renderDescriptionField = useCallback(({
    field, fieldState: { invalid, error }
  }: Parameters<GetComponentProps<typeof Controller<Schema, 'description'>>['render']>[0]) => (
    <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        variant="outlined"
        margin="dense"
        size="small"
        fullWidth
        multiline
        rows={4}
        label={descriptionLabel}
        error={invalid}
        helperText={error?.message || '\u00A0'}
        disabled={disabled}
    />
  ), [disabled, descriptionLabel]);

  const handleAddGrowth = useCallback((skill?: Skill | null) => {
    if (skill?.id) {
      const level = getSkillCurrentLevel(skill);
      if (level < SKILL_LEVEL_MAX) setSkillsGrowth((prevSkills) => uniqBy([
        ...prevSkills,
        { ...skill, current_level: level + 1 } as Skill
      ], 'id'));
    }
  }, []);

  const handleAddSkill = useCallback((skill?: Skill | ILookupSkill | null) => {
    setAnchorAddBtn(null);
    if (skill?.id) {
      const sk = {
        ...skill,
        inferred_level: SKILL_LEVEL_FIRST,
        is_inference_newer: true,
        current_level: null,
        target_level: null,
        actual_level: null,
        expected_level: null,
        skill_proficiency_level: null,
        level: null
      } as Skill;
      setOpen(true);
      setSkl(sk);
    }
  }, []);

  const handleAddSkillGroup = useCallback((sklGrp?: SkillGroup | null) => {
    if (size(sklGrp?.skills) >= 1) {
      (forGrowth ? setSkillsGrowth : setSkillsRequired)((prevSkills) => uniqBy([
        ...prevSkills,
        ...map(sklGrp?.skills, (sk) => ({
          ...sk,
          current_level: sk.expected_level || sk.current_level || SKILL_LEVEL_FIRST
        }))
      ], 'id'));
    }
    setAnchorAddBtn(null);
  }, [forGrowth]);

  const handleUpdateSkill = useCallback((level: SkillLevel | null) => {
    if (skl && level) {
      (forGrowth ? setSkillsGrowth : setSkillsRequired)((prevSkills) => findIndex(prevSkills, ['id', skl.id]) >= 0
        ? map(prevSkills, (sk) => sk.id === skl.id ? { ...sk, current_level: level } : sk)
        : uniqBy([{ ...skl, current_level: level }, ...prevSkills], 'id')
      );
      setOpen(false);
    }
  }, [skl, forGrowth]);

  const handleDeleteRequiredSkill = useCallback((id: number) => {
    if (id) setSkillsRequired((prevSkillsRequired) => reject(prevSkillsRequired, ['id', id]));
  }, []);

  const handleDeleteGrowthSkill = useCallback((id: number) => {
    if (id) setSkillsGrowth((prevSkillsGrowth) => reject(prevSkillsGrowth, ['id', id]));
  }, []);

  const handleUpdateRequiredOpen = useCallback((id: number, _abbr: string) => {
    const sk = find(skillsRequired, ['id', id]);
    if (sk) {
      setForGrowth(false);
      setOpen(true);
      setSkl(sk);
    }
  }, [skillsRequired]);

  const handleUpdateGrowthOpen = useCallback((id: number, _abbr: string) => {
    const sk = find(skillsGrowth, ['id', id]);
    if (sk) {
      setForGrowth(true);
      setOpen(true);
      setSkl(sk);
    }
  }, [skillsGrowth]);

  const excludeRequiredIds = useMemo(() => map(skillsRequired, 'id'), [skillsRequired]);
  const excludeGrowthIds = useMemo(() => map(skillsGrowth, 'id'), [skillsGrowth]);

  const renderSkillsField = useCallback(({
    field, fieldState: { invalid, error }
  }: Parameters<GetComponentProps<
    typeof Controller<Schema, 'skills_required'> | typeof Controller<Schema, 'skills_growth'>
  >['render']>[0]) => {
    const reqSkl = field.name === 'skills_required';
    return (
      <FormControl
          variant="outlined"
          size="small"
          error={invalid}
          fullWidth
      >
        <Box pt={reqSkl ? undefined : 2}>
          <BoxTypography variant="h4" pb={1}>
            <FormattedMessage id={`opportunities.input.${field.name}`}/>
          </BoxTypography>
          {map(reqSkl ? skillsRequired : skillsGrowth, (skill) => {
            const showPlus = reqSkl && getSkillCurrentLevel(skill) < SKILL_LEVEL_MAX && !find(skillsGrowth, ['id', skill.id]);
            const chip = (
              <SkillChip
                  key={showPlus ? undefined : skill.id}
                  flat
                  skill={skill}
                  level={skill.current_level}
                  disabled={disabled}
                  onActivate={reqSkl ? handleUpdateRequiredOpen : handleUpdateGrowthOpen}
                  onDelete={reqSkl ? handleDeleteRequiredSkill : handleDeleteGrowthSkill}
              />
            );
            return showPlus ? (
              <Box key={skill.id} whiteSpace="nowrap" component="span">
                {chip}
                <AddGrowthButton
                    skill={skill}
                    onClick={handleAddGrowth}
                    disabled={disabled}
                />
              </Box>
            ) : chip;
          })}
          <PlusButton
              label="supervisor.add_skill"
              disabled={disabled}
              onClick={reqSkl ? handleAddRequiredOpen : handleAddGrowthOpen}
          />
        </Box>
        <FormHelperText>
          {error?.message || '\u00A0'}
        </FormHelperText>
      </FormControl>
    );
  }, [
    skillsGrowth, skillsRequired, disabled,
    handleAddRequiredOpen, handleAddGrowthOpen, handleAddGrowth,
    handleDeleteRequiredSkill, handleDeleteGrowthSkill,
    handleUpdateRequiredOpen, handleUpdateGrowthOpen
  ]);

  // for Storybook & Jest-snapshots testing only
  useEffect(() => {
    if (testAction === true || testAction === false) {
      postOpportunity?.({ variables: {
        ...testAction ? { opportunity_id: 112 } : {},
        input: testAction
          ? { title: 'Opp' } as UpdateOpportunityMutationVariables['input']
          : {
              status: OpportunityStatus.draft, duration_value: 3, duration_unit: OpportunityDurationUnit.month,
              title: 'Opp', description: 'Desc', country_id: 1, location_id: 63
            } as NewOpportunityMutationVariables['input']
      } as UpdateOpportunityMutationVariables & NewOpportunityMutationVariables });
    } else if (isNull(testAction)) {
      deleteOpportunity?.({ variables: { opportunity_id: 121 } });
    }
  }, [testAction, postOpportunity, deleteOpportunity]);

  return (
    <>
      {pending ? <LinearProgress/> : undefined}
      {opportunityId ? undefined : (
        <>
          <CardSection top>
            <BoxTypography pb={2} variant="subtitle1">
              <FormattedMessage id="opportunities.new.title"/>
            </BoxTypography>
          </CardSection>
          <Divider/>
        </>
      )}
      {(opportunityId && failed && <FetchFailedAlert flat/>) || (
        // (opportunityId && pending && <LoadingPlaceholder flat/>) || (
        <>
          <CardSection>
            <Grid container>
              <Grid container item xs={12} alignItems="center">
                <Controller
                    name="title"
                    control={control}
                    render={renderTitleField}
                />
              </Grid>

              <GridBox container item xs={12} sm={6} md={3} pr={3} alignItems="center">
                <Controller
                    name="country_id"
                    control={control}
                    render={renderCountryField}
                />
              </GridBox>
              <GridBox container item xs={12} sm={6} md={3} pr={3} alignItems="center">
                <Controller
                    name="location_id"
                    control={control}
                    render={renderLocationField}
                />
              </GridBox>
              <GridBox
                  container item xs={12} sm={6} md={3}
                  pt={0.75} pb={2}
                  alignItems="flex-start" justifyContent="center"
              >
                <Controller
                    name="remote"
                    control={control}
                    render={renderBooleanField}
                />
              </GridBox>
              <GridBox container item xs={12} sm={6} md={3} pl={3} pt={0.5} alignItems="flex-start" justifyContent="flex-end">
                <Box flex="1 1 0" display="flex" alignItems="flex-start" justifyContent="space-between">
                  <Controller
                      name="timezone_required"
                      control={control}
                      render={renderTimezoneRequiredField}
                  />
                  {isTimezoneRequired ? (
                    <Controller
                        name="timezone"
                        control={control}
                        render={renderTimezoneField}
                    />
                  ) : (
                    <BoxTypography variant="subtitle2" flex="1 1 0" pt={1.375} pl={0.75} justifyContent="flex-start">
                      <FormattedMessage id="opportunities.input.timezone_required"/>
                    </BoxTypography>
                  )}
                </Box>
              </GridBox>

              <Grid container item xs={12} sm={6} md={3} pr={3} alignItems="center">
                <Controller
                    name="scope_manager_id"
                    control={control}
                    render={renderScopeManagerField}
                />
              </Grid>
              <GridBox
                  container item xs={12} sm={6} md={3}
                  pt={0.75} pb={2}
                  alignItems="flex-start" justifyContent="center"
              >
                <Controller
                    name="fulltime"
                    control={control}
                    render={renderBooleanField}
                />
              </GridBox>
              <GridBox container item xs={12} sm={6} md={3} pr={3} alignItems="center">
                <Controller
                    name="duration_value"
                    control={control}
                    render={renderDurationField}
                />
              </GridBox>
              <GridBox container item xs={12} sm={6} md={3} alignItems="center" justifyContent="flex-end">
                <Controller
                    name="start_date"
                    control={control}
                    render={renderStartDateField}
                />
              </GridBox>

              <Grid container item xs={12} alignItems="center">
                <Controller
                    name="description"
                    control={control}
                    render={renderDescriptionField}
                />
              </Grid>
            </Grid>
          </CardSection>
          <Divider/>
          <CardSection>
            <Grid container>
              <Grid container item xs={12} alignItems="center">
                <Controller
                    name="skills_required"
                    control={control}
                    render={renderSkillsField}
                />
              </Grid>
              <Grid container item xs={12} alignItems="center">
                <Controller
                    name="skills_growth"
                    control={control}
                    render={renderSkillsField}
                />
              </Grid>
            </Grid>
          </CardSection>
          <CardFooter withDivider>
            <Button
                color="primary"
                variant="outlined"
                disabled={disabled}
                component={RouterLink}
                to={opportunityId
                  ? injectParams(PATH_SUPERVISOR_OPPORTUNITY, { opp_id: opportunityId })
                  : PATH_SUPERVISOR_OPPORTUNITIES}
            >
              <FormattedMessage id="common.button.back"/>
            </Button>
            <Box width="5%"/>
            <Button
                type="submit"
                color="primary"
                variant="contained"
                disableElevation
                startIcon={newPending ? <CircularProgress size={18} color="inherit"/> : undefined}
                // TODO: detect `dirty` form state and disable Save button if there are no changes
                disabled={disabled}
                onClick={handleSubmit(onSubmit)}
            >
              <FormattedMessage
                  id={!opportunityId || opportunity?.status === OpportunityStatus.draft
                    ? 'opportunities.button.save_draft' : 'opportunities.button.save'}
              />
            </Button>
            {opportunityId ? (
              <>
                <Box width="5%"/>
                <Button
                    color="error"
                    variant="outlined"
                    startIcon={deletePending ? <CircularProgress size={18} color="inherit"/> : undefined}
                    disabled={disabled}
                    onClick={handleDelete}
                >
                  <FormattedMessage id="opportunities.button.delete"/>
                </Button>
              </>
            ) : undefined}
          </CardFooter>
          <AddSkillsDialog
              anchorEl={anchorAddBtn}
              exclude={forGrowth ? excludeGrowthIds : excludeRequiredIds}
              onAdd={handleAddSkill}
              onAddGroup={handleAddSkillGroup}
              onCancel={handleAddClose}
              disabled={disabled}
          />
          {skl ? (
            <SkillLevelDialog
                isOpen={open}
                skill={skl}
                plain
                depersonalized
                onUpdate={handleUpdateSkill}
                onCancel={handleUpdateClose}
                onExited={handleUpdateExited}
                disabled={disabled}
            />
          ) : undefined}
          {opportunityId && confirmMounted ? (
            <ConfirmDialog
                open={confirmOpen}
                title="opportunities.delete.title"
                text="opportunities.delete.question"
                withCancelButton
                onCancel={handleCancel}
                onConfirm={handleConfirm}
                onExited={handleExited}
            />
          ) : undefined}
          <ActionFailedAlert
              message="opportunities.new.error"
              open={newFailed}
          />
          {opportunityId ? (
            <ActionFailedAlert
                message="opportunities.delete.error"
                open={deleteFailed}
            />
          ) : undefined}
        </>
      )}
    </>
  );
};

OpportunityEditorPanel.propTypes = OpportunityEditorPanelPropsTypes;

export default memo(OpportunityEditorPanel);

import { useCallback, useMemo } from 'react';
import size from 'lodash/size';
import head from 'lodash/head';
import toLower from 'lodash/toLower';
// EmPath UI Components
import { type Item } from '@empathco/ui-components/src/models/item';
// local imports
import type { AdminUser, Location } from '../graphql/types';
import type { ManagementLevel } from '../constants/managementLevel';
import {
  UserRole,
  ROLE_CONTRACTOR, ROLE_EMPLOYEE, ROLE_MANAGER, ROLE_HR, ROLE_LEADER, ROLE_ADMIN
} from '../constants/userRoles';
import type { EmployeeBrief } from './employee';
import type { Job } from './job';
import type { OrgUnit } from './orgUnit';
import useCustomerSettings from '../config/customer';

export type OnboardingSteps = Record<string, boolean>;

export interface User extends EmployeeBrief {
  id: number;
  code: string;
  first_name: string;
  last_name: string;
  email?: string | null;
  is_superuser?: boolean | null;
  is_supervisor?: boolean | null;
  is_international?: boolean | null;
  management_level?: ManagementLevel | null;
  current_job?: Job;
  location?: Location | null;
  onboarding_steps?: OnboardingSteps | null;
  org_unit?: OrgUnit | null;
  org?: Partial<Item> | null;
  roles?: Partial<Record<UserRole, boolean>> | null;
  leader?: {
    code?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    email?: string | null;
  } | null;
  delegated_leaders?: EmployeeBrief[] | null;
  has_current_job_skills?: boolean | null;
  has_in_demand_skills?: boolean | null;
  user?: AdminUser | null;
}

// pure user roles:
export const isContractor = (user?: User | null): boolean => Boolean(user?.roles?.[ROLE_CONTRACTOR]);
export const isNotContractor = (user?: User | null): boolean => Boolean(user && !user.roles?.[ROLE_CONTRACTOR]);
export const isEmployee = (user?: User | null): boolean => Boolean(
  user?.roles?.[ROLE_EMPLOYEE] || user?.roles?.[ROLE_CONTRACTOR]
);
export const isManager = (user?: User | null): boolean => Boolean(user?.roles?.[ROLE_MANAGER]);
export const isLeader = (user?: User | null): boolean => Boolean(user?.roles?.[ROLE_LEADER]);
export const isHRBP = (user?: User | null): boolean => Boolean(user?.roles?.[ROLE_HR]);
export const isAdmin = (user?: User | null): boolean => Boolean(user?.is_superuser || user?.roles?.[ROLE_ADMIN]);

// combinations of user roles:
export const isLeaderOrHR = (user?: User | null): boolean => isLeader(user) || isHRBP(user);
export const isHROnly = (user?: User | null): boolean => isHRBP(user) && !isLeader(user);
export const isLeaderOnly = (user?: User | null): boolean => isLeader(user) && !isHRBP(user);

// mixtures of user roles and permissions => privileges:
export const hasDelegation = (user?: User | null): boolean => Boolean(size(user?.delegated_leaders) >= 1);
export const hasLeadership = (user?: User | null): boolean => isLeader(user) || hasDelegation(user);
export const hasHRBP = (user?: User | null): boolean => hasLeadership(user) || isHRBP(user);
export const hasLeadershipOnly = (user?: User | null): boolean => hasLeadership(user) && !isHRBP(user);
export const hasDelegationOnly = (user?: User | null): boolean => hasDelegation(user) && !isLeaderOrHR(user);
export const isAdminOnly = (user?: User | null): boolean => isAdmin(user) &&
  !isEmployee(user) && !isManager(user) && !isLeader(user) && !isHRBP(user);

export const getDelegator = (user?: User | null): EmployeeBrief | undefined =>
  (user && !isLeaderOrHR(user) && head(user.delegated_leaders)) || undefined;

const getLeader = (user?: User | null): EmployeeBrief | undefined => (user && (
  // Leader's own ID:
  isLeader(user) ? user
  // Delegated Leader's ID:
  : head(user.delegated_leaders)
)) || undefined;

export const getRootLeaderId = (user?: User | null): string | undefined =>
  (!isHRBP(user) && getLeader(user)?.code) || undefined;

export const getDefaultLeaderId = (user?: User | null): string =>
  (user && isHROnly(user) && user.leader?.code) || getLeader(user)?.code || '0';

export const getLeaderId = (user?: User | null): string | undefined => (user && (
  isLeader(user) ? user.code : user.leader?.code
)) || undefined;

export const getSelectedLeaderId = (user?: User | null): string | undefined => {
  const code = getRootLeaderId(user);
  return (code && toLower(code)) || undefined;
};

function useUserModel() {
  const { getOrgUnitSplash } = useCustomerSettings();
  const hasNoAccess = useCallback((user?: User | null): boolean => !user || (!user.is_superuser && (!user.roles || (
    !user.roles[ROLE_CONTRACTOR] &&
    !user.roles[ROLE_EMPLOYEE] &&
    !user.roles[ROLE_ADMIN] &&
    !user.roles[ROLE_MANAGER] &&
    !user.roles[ROLE_LEADER] &&
    !user.roles[ROLE_HR]
  ))) || getOrgUnitSplash(user.org_unit), [getOrgUnitSplash]);
  return useMemo(() => ({ hasNoAccess }), [hasNoAccess]);
}

export default useUserModel;

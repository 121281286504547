import transform from 'lodash/transform';
import isSafeInteger from 'lodash/isSafeInteger';
import isPlainObject from 'lodash/isPlainObject';
import isNull from 'lodash/isNull';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import pick from 'lodash/pick';
import size from 'lodash/size';
import omit from 'lodash/omit';
import toString from 'lodash/toString';
import toFinite from 'lodash/toFinite';
import toSafeInteger from 'lodash/toSafeInteger';
// local imports
import { isValidRoleScope } from '../constants/scopes';
import {
  ActionState, ContextEntity, ContextEntityWithCount, ContextObject, ContextParams, ContextState,
  IContextEntity, IContextEntityWithCount, IContextObject, ICountedData, StateFlag
} from '../models/contextEntity';
import { Settings } from '../models/settings';

export const getInitialActionState = <P = ContextParams>(): ActionState<P> => ({
  pending: null,
  failed: null,
  params: null
});

export const getInitialObjectState = <T, P = ContextParams>(): ContextObject<T, P> => ({
  data: null,
  pending: null,
  failed: null,
  params: null
});

export const getInitialStateWithCount = <T, P = ContextParams>(): ContextEntityWithCount<T, P> => ({
  data: null,
  count: null,
  pending: null,
  failed: null,
  params: null
});

export const getInitialState = <T, P = ContextParams>(): ContextEntity<T, P> => ({
  data: null,
  pending: null,
  failed: null,
  params: null
});

export const getPendingObjectState = <T, P = ContextParams>(
  params: P | null,
  data: T | null = null
): ContextObject<T, P> => ({
  data,
  pending: true,
  failed: null,
  params
});

export const getPendingStateWithCount = <T, P = ContextParams>(
  params: P | null,
  data: T[] | null = null
): ContextEntityWithCount<T, P> => ({
  data,
  count: null,
  pending: true,
  failed: null,
  params
});

export const getPendingState = <T, P = ContextParams>(
  params: P | null,
  data: T[] | null = null
): ContextEntity<T, P> => ({
  data,
  pending: true,
  failed: null,
  params
});

export const getFetchedObjectState = <T, P = ContextParams>(
  data: T | null,
  params: P
): ContextObject<T, P> => ({
  data,
  pending: false,
  failed: data === null,
  params
});

export const getFetchedStateWithCount = <T, P = ContextParams>(
  data: ICountedData<T> | null,
  params: P,
  extraResults?: string[]
): ContextEntityWithCount<T, P> => ({
  ...data && extraResults ? pick(data, extraResults) : {},
  data: data ? data.data : null,
  count: data ? data.count : null,
  pending: false,
  failed: data === null,
  params
});

export const getFetchedState = <T, P = ContextParams>(
  data: T[] | null,
  params: P
): ContextEntity<T, P> => ({
  data,
  pending: false,
  failed: data === null,
  params
});

export const updateStateCountParams = <T, P = ContextParams>(
  entity: IContextEntityWithCount<T, P>,
  params: P
): IContextEntityWithCount<T, P> => ({ ...entity, params });

export const updateStateParams = <T, P = ContextParams>(
  entity: IContextEntity<T, P>,
  params: P
): IContextEntity<T, P> => ({ ...entity, params });

export const updateStateObjectParams = <T, P = ContextParams>(
  entity: IContextObject<T, P>,
  params: P
): IContextObject<T, P> => ({ ...entity, params });

export const updateJobId = <T, P = ContextParams>(
  entity: IContextEntityWithCount<T, P>,
  jobId: number
): IContextEntityWithCount<T, P> => ({
  ...entity,
  pending: null,
  jobId
});

export const getActionPendingState = <P = ContextParams>(params: P): ActionState<P> => ({
  pending: true,
  failed: null,
  params
});
export const getActionFinishedState = <T, P = ContextParams>(
  payload: T,
  params: P
): ActionState<P> => ({
  pending: false,
  failed: !payload,
  params
});

export const clearActionStates = <T = ContextState>(state: T): T => transform(
  state as unknown as ContextState,
  (result, value, key) => {
    if (isPlainObject(value) &&
      !isNull((value as IContextEntity<unknown>).failed) &&
      isNil((value as IContextEntity<unknown>).data)
    ) {
      const entity = value as IContextEntity<unknown>;
      const params = entity.params ? pick(entity.params, 'offline') : null;
      (result as unknown as ContextState)[key] = entity.params
        ? { ...entity, failed: null, pending: null, params: size(params) > 0 ? params : null }
        : { ...entity, failed: null, pending: null };
    } else {
      (result as unknown as ContextState)[key] = value;
    }
  },
  {} as T
);

export const getRequestHeaders = (token: string | boolean) => token === true ? undefined : {
  Authorization: `Token ${token}`
};

export const alreadyDone = <T, P = ContextParams>(
  current: T[] | T | null,
  pending: StateFlag,
  failed: StateFlag,
  params: P | null = {} as P,
  newParams: P | null = {} as P,
  retryWithParams = false
): boolean => Boolean(pending) || (
  (
    (!isNil(current) && pending === false && failed === false) ||
    (Boolean(failed) && (!retryWithParams || isEmpty(omit(newParams as ContextParams, 'offline'))))
  ) && (
    (isEmpty(params) && isEmpty(newParams)) ||
    isEqual(params, newParams)
  )
);

export const keepDataOffline = <T>(
  online: boolean | 'yes',
  current: T[] | T | null,
  pending: StateFlag,
  failed: StateFlag
): boolean => {
  if (!online) {
    if (!isNil(current) && pending === false && failed === false) return true;
    throw new Error();
  }
  return false;
};

export const optimizeParams = <P = ContextParams>(
  params: P = {} as P, online: boolean | 'yes' = true
): P => pickBy(
  { ...params || {}, ...online ? {} : { offline: true }} as ContextParams,
  (value) => !isNil(value)
) as unknown as P;

export const sanitizeRoleScope = <P = ContextParams>(params: P = {} as P): P => {
  if (isPlainObject(params) &&
    !isNil((params as ContextParams).scope) &&
    !isValidRoleScope((params as ContextParams).scope as string)
  ) {
    (params as ContextParams).scope = null;
  }
  return params;
};

export const getSettingsIntValue = (settings: Settings | null, id: string): number | undefined =>
  isNil(settings) || isNil(settings[id]) ? undefined : toSafeInteger(settings[id]);

export const getSettingsNumValue = (settings: Settings | null, id: string): number | undefined =>
  isNil(settings) || isNil(settings[id]) ? undefined : toFinite(settings[id]);

export const getSettingsBoolValue = (settings: Settings | null, id: string): boolean | undefined =>
  isNil(settings) || isNil(settings[id]) ? undefined : Boolean(settings[id]);

export const getSettingsStrValue = (settings: Settings | null, id: string): string | undefined =>
  isNil(settings) || isNil(settings[id]) ? undefined : toString(settings[id]);


export interface LocationParams {
  country_id?: number | null;
  state_id?: number | null;
  location_id?: number | null;
}

export const locationParams = (
  country_id?: number | null,
  state_id?: number | null,
  location_id?: number | null
): LocationParams => ({
  ...location_id && location_id >= 1 && isSafeInteger(location_id) ? { location_id } : {},
  ...state_id && state_id >= 1 && isSafeInteger(state_id) &&
    (!location_id || location_id < 1 || !isSafeInteger(location_id))
    ? { state_id } : {},
  ...country_id && country_id >= 1 && isSafeInteger(country_id) &&
    (!location_id || location_id < 1 || !isSafeInteger(location_id)) &&
    (!state_id || state_id < 1 || !isSafeInteger(state_id))
    ? { country_id } : {}
});

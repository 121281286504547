import { memo, useMemo, type FunctionComponent } from 'react';
import { useLocation, useParams } from 'react-router-dom';
// EmPath UI Components
import useCurrentPath from '@empathco/ui-components/src/hooks/useCurrentPath';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTabbar from '@empathco/ui-components/src/elements/CardTabbar';
// local imports
import { AdminReportType } from '../graphql/types';
import { ADMIN_REPORT_TYPES } from '../graphql/customTypes';
import usePathConfig, {
  PATH_ADMIN_EMPLOYEES, PATH_ADMIN_JOBS, PATH_ADMIN_REPORTS, PATH_ADMIN_REPORT, PATH_ADMIN_USERS, PATH_ADMIN_SETTINGS
} from '../config/paths';
import { getReportId, getReportType } from '../helpers/routerParams';
import useVerifyUser from '../hooks/useVerifyUser';
import Screen from '../v3/Screen';
import AdminEmployees from '../panels/AdminEmployees';
import AdminJobs from '../panels/AdminJobs';
import AdminReports from '../panels/AdminReports';
import AdminReport from '../panels/AdminReport';
import AdminUsers from '../panels/AdminUsers';
import AdminSettings from '../panels/AdminSettings';

const AdminScreen: FunctionComponent = () => {
  const { search } = useLocation();
  const { ROUTES } = usePathConfig();
  const path = useCurrentPath(ROUTES);
  const params = useParams();
  const report_id = getReportId(params);
  const report_type = getReportType(params);
  
  const { content } = useVerifyUser({ admin: true });
    
  const reportType = report_id && report_type && ADMIN_REPORT_TYPES.includes(report_type as AdminReportType)
    ? report_type as AdminReportType : undefined;
  const reportId = report_id && reportType ? report_id : undefined;

  const isEmployees = path === PATH_ADMIN_EMPLOYEES;
  const isJobs = path === PATH_ADMIN_JOBS;
  const isReport = path === PATH_ADMIN_REPORT && Boolean(reportId) && Boolean(reportType);
  const isReports = path === PATH_ADMIN_REPORTS || isReport;
  const isUsers = path === PATH_ADMIN_USERS;
  const isSettings = path === PATH_ADMIN_SETTINGS;

  const breadcrumbs = useMemo(() =>
    (isEmployees && ['admin.employees']) ||
    (isJobs && ['admin.jobs']) ||
    (isReports && (
      isReport ? [
        {
          title: 'admin.reports',
          link: PATH_ADMIN_REPORTS
        },
        `admin.report.${reportType}.title`
      ]
      : ['admin.reports'])
    ) ||
    (isUsers && ['admin.users']) ||
    (isSettings && ['admin.settings']) ||
    undefined, [isEmployees, isJobs, isReports, isReport, reportType, isUsers, isSettings]);

  const items = useMemo(() => [
    {
      title: 'admin.employees',
      active: isEmployees,
      link: `${PATH_ADMIN_EMPLOYEES}${isEmployees ? search : ''}`
    },
    {
      title: 'admin.jobs',
      active: isJobs,
      link: `${PATH_ADMIN_JOBS}${isJobs ? search : ''}`
    },
    {
      title: 'admin.reports',
      active: isReport,
      link: PATH_ADMIN_REPORTS
    },
    {
      title: 'admin.users',
      active: isUsers,
      link: `${PATH_ADMIN_USERS}${isUsers ? search : ''}`
    },
    {
      title: 'admin.settings',
      link: PATH_ADMIN_SETTINGS
    }
  ], [search, isEmployees, isJobs, isReport, isUsers]);

  return content || (
    <Screen
        admin
        withSettings
        breadcrumbs={breadcrumbs}
    >
      <ContentCard>
        <CardTabbar items={items}>
          <AdminEmployees/>
          <AdminJobs/>
          {reportId && reportType ? (
            <AdminReport
                reportId={reportId}
                reportType={reportType}
            />
          ) : <AdminReports/>}
          <AdminUsers/>
          <AdminSettings/>
        </CardTabbar>
      </ContentCard>
    </Screen>
  );
};

export default memo(AdminScreen);

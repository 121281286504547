import { useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react';
import isBoolean from 'lodash/isBoolean';
// local imports
import { SortDirection } from '../graphql/types';
import { getSettingsBoolValue, getSettingsStrValue } from '../helpers/context';
import { DataContext } from '../context';

function useTableSort<T extends string>({
  settingsId,
  sortValues,
  defaultDirections,
  defaultSortBy,
  defaultDirection
}: {
  settingsId: string;
  sortValues: readonly T[];
  defaultDirections: Record<T, SortDirection>;
  defaultSortBy?: T | null;
  defaultDirection?: SortDirection | null;
}) {
  const {
    settings: { data: settingsData, pending: pendingSettings, failed: failedSettings },
    updateSettings
  } = useContext(DataContext);
  const settingsLoaded = pendingSettings === false && failedSettings === false && Boolean(settingsData);
  const settings = settingsLoaded ? settingsData : null;

  const settingsSort = getSettingsStrValue(settings, `${settingsId}__sort`);
  const settingsDirection = getSettingsBoolValue(settings, `${settingsId}__direction`);

  const [resetSort] = sortValues;
  const resetDir = defaultDirections[resetSort];

  const [defaultSort, defaultDir] = useMemo(() => {
    let sortVal = resetSort;
    let dirVal = resetDir;
    if (defaultSortBy && sortValues.includes(defaultSortBy)) {
      sortVal = defaultSortBy as T;
      dirVal = defaultDirection || defaultDirections[sortVal];
    } else if (settingsSort && sortValues.includes(settingsSort as T)) {
      sortVal = settingsSort as T;
      dirVal = (settingsDirection === true && SortDirection.ascending) ||
        (settingsDirection === false && SortDirection.descending) ||
        defaultDirections[sortVal];
    }
    return [sortVal, dirVal];
  }, [defaultDirection, defaultSortBy, settingsSort, settingsDirection, resetDir, resetSort, sortValues, defaultDirections]);

  // eslint-disable-next-line react/hook-use-state
  const [{ sort, dir }, setSort] = useState<{
    sort: T;
    dir: SortDirection;
  }>({
    sort: defaultSort,
    dir: defaultDir
  });
  const canResetSort = sort !== resetSort || dir !== resetDir;

  useLayoutEffect(() => {
    setSort({ sort: defaultSort, dir: defaultDir });
    const dirBoolVal = defaultDir === SortDirection.ascending;
    if (settingsSort !== defaultSort || Boolean(settingsDirection) !== dirBoolVal) updateSettings?.({
      [`${settingsId}__sort`]: defaultSort,
      [`${settingsId}__direction`]: dirBoolVal
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSort, defaultDir]); // ignoring: settingsSort, settingsDirection, settingsId, updateSettings

  const handleSort = useCallback((sortValue: T, newDir: SortDirection) => {
    if (sortValue && newDir) {
      setSort({ sort: sortValue, dir: newDir });
      const dirBoolVal = newDir === SortDirection.ascending;
      if (settingsSort !== sortValue || Boolean(settingsDirection) !== dirBoolVal) updateSettings?.({
        [`${settingsId}__sort`]: sortValue,
        [`${settingsId}__direction`]: dirBoolVal
      });
    }
  }, [settingsDirection, settingsSort, updateSettings, settingsId]);

  const handleSortLegacy = useCallback((sortValue: T, newDir: boolean) => {
    if (sortValue && isBoolean(newDir)) handleSort(
      sortValue,
      newDir ? SortDirection.ascending : SortDirection.descending
    );
  }, [handleSort]);

  const handleSortReset = useCallback(() => {
    setSort({ sort: resetSort, dir: resetDir });
  }, [resetSort, resetDir]);

  return useMemo(() => ({
    sort,
    dir,
    canResetSort,
    handleSort,
    handleSortLegacy,
    handleSortReset
  }), [sort, dir, canResetSort, handleSort, handleSortLegacy, handleSortReset]);
}

export default useTableSort;

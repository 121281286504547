import { forwardRef, memo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Typography from '@mui/material/Typography';
// EmPath UI Components
import GridBox from '@empathco/ui-components/src/mixins/GridBox';
import YesNoButtonGroup from '@empathco/ui-components/src/elements/YesNoButtonGroup';
// local imports
import { EmailNotificationType, EmailNotificationReducedType } from '../constants/emailNotifications';
import PreferencesCheckbox from '../v3/PreferencesCheckbox';

export interface PreferencesRowChoice {
  id: EmailNotificationType | EmailNotificationReducedType;
  text: string;
}

// TODO: convert to generic (value & onChange; values & onValuesChange)
type PreferencesRowProps = {
  prompt: string;
  value?: boolean;
  values?: Partial<Record<EmailNotificationType | EmailNotificationReducedType, boolean>> | null;
  choices?: PreferencesRowChoice[];
  onChange?: (id: boolean) => void;
  onValuesChange?: (id: string) => void;
  disabled?: boolean | null;
}

const PreferencesRowPropTypes = {
  // attributes
  prompt: PropTypes.string.isRequired,
  value: PropTypes.bool,
  values: PropTypes.object,
  choices: PropTypes.arrayOf(PropTypes.object.isRequired as Validator<PreferencesRowChoice>),
  onChange: PropTypes.func,
  onValuesChange: PropTypes.func,
  disabled: PropTypes.bool
};

const PreferencesRow = forwardRef<HTMLDivElement, PreferencesRowProps>(({
  prompt,
  value = false,
  values,
  choices,
  onChange,
  onValuesChange,
  disabled = false
}, ref) => {
  const hasChoices = Boolean(choices);
  return (
    <GridBox
        ref={ref}
        container
        pt={hasChoices ? 1 : 2}
        pb={hasChoices ? 3 : 2}
    >
      <GridBox
          item
          container
          xs={12}
          sm={hasChoices ? undefined : 6}
          md={hasChoices ? undefined : 7}
          alignItems="center"
          py={hasChoices ? 2 : 1}
          pr={hasChoices ? undefined : 2}
      >
        <Typography variant="subtitle1">
          <FormattedMessage id={prompt}/>
        </Typography>
      </GridBox>
      {map(choices || [{} as PreferencesRowChoice], ({ id, text }, index) => (
        <GridBox
            key={index}
            item
            container
            xs={12}
            sm={hasChoices ? undefined : 6}
            md={hasChoices ? 6 : 5}
            lg={hasChoices ? undefined : 4}
            xl={hasChoices ? 4 : 3}
            alignItems="center"
            py={hasChoices ? undefined : 1}
        >
          {id ? onValuesChange && (
            <PreferencesCheckbox
                id={id}
                title={text}
                value={Boolean(values && values[id])}
                onChange={onValuesChange}
                disabled={disabled}
            />
          ) : onChange && (
            <YesNoButtonGroup ariaLabel={prompt} noRipple value={value} onChange={onChange} disabled={disabled}/>
          )}
        </GridBox>
      ))}
    </GridBox>
  );
});

PreferencesRow.displayName = 'PreferencesRow';

PreferencesRow.propTypes = PreferencesRowPropTypes;

export default memo(PreferencesRow);

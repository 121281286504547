/* eslint-disable max-lines */
import {
  forwardRef, memo, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState, type Component
} from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import { useLazyQuery } from '@apollo/client';
// Material UI imports
import Box, { type BoxProps } from '@mui/material/Box';
// Material Icon imports
import RemoveIcon from '@mui/icons-material/Remove';
// EmPath UI Components
import { ReactComponent } from '@empathco/ui-components/src/helpers/react';
import { type ExportFormat } from '@empathco/ui-components/src/models/exportFormat';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import FilterSelector from '@empathco/ui-components/src/elements/FilterSelector';
import ExportButton from '@empathco/ui-components/src/elements/ExportButton';
import LevelSelector from '@empathco/ui-components/src/elements/LevelSelector';
import ScopeSelector from '@empathco/ui-components/src/elements/ScopeSelector';
import OnOffSwitch from '@empathco/ui-components/src/elements/OnOffSwitch';
// local imports
import { ORGS_QUERY } from '../graphql/Orgs';
import { JOB_CATEGORIES_QUERY } from '../graphql/JobCategories';
import { CAREER_LADDERS_QUERY } from '../graphql/CareerLadders';
import { COUNTRIES_QUERY } from '../graphql/Countries';
import { STATES_QUERY } from '../graphql/States';
import {
  LookupItem, Org, State,
  OrgsDocument, JobCategoriesDocument, CareerLaddersDocument, CountriesDocument, StatesDocument
} from '../graphql/types';
import { isValidRoleScope, RoleScope, ROLE_SCOPES } from '../constants/scopes';
import { ILookupItem } from '../models/lookupItem';
import { MAX_COUNTRIES_OPTIONS, MAX_LOOKUP_OPTIONS } from '../config/params';
import useCustomerSettings from '../config/customer';
import useModels, { sanitizeJobLevel, sanitizeLookup, sanitizeMatchRate } from '../helpers/models';
import { getSettingsBoolValue, getSettingsIntValue, getSettingsNumValue, getSettingsStrValue } from '../helpers/context';
import { JobsIndexParams } from '../context/dataContext';
import { DataContext } from '../context';
import MatchRateSlider from '../elements/MatchRateSlider';
// SCSS imports
import { openReqsFilter, supervisoryFilter, matchRateFilter } from './JobsIndexFilters.module.scss';

const JOBS_FILTERS_DISPLAY_NAME = 'JobsIndexFilters' as const;

export type JobsIndexFiltersComponent = ReactComponent<JobsIndexFiltersProps>;

export const hasFilters = (filters?: JobsIndexFiltersComponent | null) => {
  if (!filters) return false;
  if (filters.type?.displayName !== JOBS_FILTERS_DISPLAY_NAME) return true;
  return Boolean(filters.props?.visible);
};

const NO_LOOKUP_ITEMS = [] as ILookupItem[];

export type JobsIndexFilterValues = Omit<JobsIndexParams, 'sort_by' | 'direction'>;

type JobsIndexFiltersProps = {
  settingsId?: string;
  init?: JobsIndexFilterValues | null;
  visible: boolean;
  onChange: (filters: JobsIndexFilterValues) => void;
  onExport?: (format: ExportFormat) => void;
  dropdownsFirst?: boolean | null;
  showOpenReqs?: boolean;
  resetFilters?: boolean;
  withOpenReqs?: boolean;
  withLevel?: boolean;
  withScope?: boolean;
  withSupervisory?: boolean;
  withMatchRate?: boolean;
  withOrg?: boolean;
  withCategory?: boolean;
  withLadder?: boolean;
  withCountry?: boolean;
  withState?: boolean;
  domesticCountriesOnly?: boolean;
  isInternational?: boolean | null;
  disabled?: boolean | null;
  exportPending?: boolean | null;
  exportDisabled?: boolean | null;
}

const JobsIndexFiltersPropTypes = {
  // attributes
  settingsId: PropTypes.string,
  init: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onExport: PropTypes.func,
  dropdownsFirst: PropTypes.bool,
  showOpenReqs: PropTypes.bool,
  resetFilters: PropTypes.bool,
  withOpenReqs: PropTypes.bool,
  withLevel: PropTypes.bool,
  withScope: PropTypes.bool,
  withSupervisory: PropTypes.bool,
  withMatchRate: PropTypes.bool,
  withOrg: PropTypes.bool,
  withCategory: PropTypes.bool,
  withLadder: PropTypes.bool,
  withCountry: PropTypes.bool,
  withState: PropTypes.bool,
  domesticCountriesOnly: PropTypes.bool,
  isInternational: PropTypes.bool,
  disabled: PropTypes.bool,
  exportPending: PropTypes.bool,
  exportDisabled: PropTypes.bool
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const JobsIndexFilters = forwardRef<Component<BoxProps>, JobsIndexFiltersProps>(({
  settingsId,
  init,
  visible,
  onChange,
  onExport,
  dropdownsFirst = false,
  showOpenReqs: parentShowOpenReqs,
  resetFilters = false,
  withOpenReqs = false,
  withLevel = false,
  withScope = false,
  withSupervisory = false,
  withMatchRate = false,
  withOrg = false,
  withCategory = false,
  withLadder = false,
  withCountry = false,
  withState = false,
  domesticCountriesOnly = false,
  isInternational = false,
  disabled: parentDisabled = false,
  exportPending = false,
  exportDisabled = false
}, ref) => {
  const { DEFAULT_MATCH_RATE, MANAGEMENT_LEVEL_FIRST, MANAGEMENT_LEVEL_LAST } = useCustomerSettings();
  const { getDomesticCountryId, isDomesticCountryId } = useModels();
  const {
    settings: { data: settingsData, pending: pendingSettings, failed: failedSettings },
    settingsUpdate: { pending: pendingSettingsUpdate }, updateSettings
  } = useContext(DataContext);

  // lazy load orgs
  const { query: getOrgs, pending: orgsPending, failed: orgsFailed, results: orgsData } = useQueryCounted({
    data: undefined as unknown as Org,
    key: 'orgs',
    lazyQuery: useLazyQuery(ORGS_QUERY as typeof OrgsDocument)
  });

  // lazy load job categories
  const {
    query: getCategories, pending: pendingCategories, failed: failedCategories, results: categoriesData
  } = useQueryCounted({
    data: undefined as unknown as LookupItem,
    key: 'jobCategories',
    lazyQuery: useLazyQuery(JOB_CATEGORIES_QUERY as typeof JobCategoriesDocument)
  });
  // lazy load career ladders
  const { query: getLadders, pending: pendingLadders, failed: failedLadders, results: laddersData } = useQueryCounted({
    data: undefined as unknown as LookupItem,
    key: 'careerLadders',
    lazyQuery: useLazyQuery(CAREER_LADDERS_QUERY as typeof CareerLaddersDocument)
  });

  // lazy load countires
  const { query: getCountries, pending: pendingCountries, failed: failedCountries, results: countriesData } = useQueryCounted({
    data: undefined as unknown as LookupItem,
    key: 'countries',
    lazyQuery: useLazyQuery(COUNTRIES_QUERY as typeof CountriesDocument)
  });
  // lazy load states
  const { query: getStates, pending: pendingStates, results: statesData } = useQueryCounted({
    data: undefined as unknown as State,
    key: 'states',
    lazyQuery: useLazyQuery(STATES_QUERY as typeof StatesDocument)
  });

  const orgs = ((!withOrg || orgsFailed) && NO_LOOKUP_ITEMS) || (orgsPending ? null : orgsData);
  const categories = ((!withCategory || failedCategories) && NO_LOOKUP_ITEMS) || (pendingCategories ? null : categoriesData);
  const ladders = ((!withLadder || failedLadders) && NO_LOOKUP_ITEMS) || (pendingLadders ? null : laddersData);
  const countries = (!withCountry && !domesticCountriesOnly && NO_LOOKUP_ITEMS) || (pendingCountries ? null : countriesData);
  const states = pendingStates ? null : statesData;

  const settingsLoaded = (!settingsId && Boolean(init)) || (
    pendingSettings === false && failedSettings === false && Boolean(settingsData));
  const settings = settingsId && settingsLoaded ? settingsData : null;

  const [showOpenReqs, setShowOpenReqs] = useState(parentShowOpenReqs);
  const [resetScope, setResetScope] = useState(Boolean(resetFilters));
  const [resetOpenReqs, setResetOpenReqs] = useState(Boolean(resetFilters));
  const [resetSupervisory, setResetSupervisory] = useState(Boolean(resetFilters));
  const [resetMatchRate, setResetMatchRate] = useState(Boolean(resetFilters));
  const [resetLevel, setResetLevel] = useState(Boolean(resetFilters));
  const [resetLevelMax, setResetLevelMax] = useState(Boolean(resetFilters));
  const [resetOrg, setResetOrg] = useState(Boolean(resetFilters));
  const [resetCategory, setResetCategory] = useState(Boolean(resetFilters));
  const [resetLadder, setResetLadder] = useState(Boolean(resetFilters));
  const [resetCountry, setResetCountry] = useState(Boolean(resetFilters));
  const [resetState, setResetState] = useState(Boolean(resetFilters));

  /* eslint-disable no-nested-ternary */
  const settingsScope = withScope
    ? init
      ? init.scope || undefined
      : (settingsId && getSettingsStrValue(settings, `${settingsId}__scope`)) || undefined
    : undefined;
  const settingsOpenReqs = withOpenReqs
    ? init
      ? init.open_reqs_only || undefined
      : (settingsId && getSettingsBoolValue(settings, `${settingsId}__open_reqs`)) || undefined
    : undefined;
  const settingsSupervisory = withSupervisory
    ? init
      ? init.supervisory_jobs_only || undefined
      : (settingsId && getSettingsBoolValue(settings, `${settingsId}__supervisory`)) || undefined
    : undefined;
  const settingsMatchRate = withMatchRate
    ? init
      ? init.min_match_rate || undefined
      : (settingsId && getSettingsNumValue(settings, `${settingsId}__match_rate`)) || undefined
    : undefined;
  const settingsOrg = withOrg
    ? init
      ? init.org_id || undefined
      : (settingsId && getSettingsIntValue(settings, `${settingsId}__org`)) || undefined
    : undefined;
  const settingsCategory = withCategory
    ? init
      ? init.job_category_id || undefined
      : (settingsId && getSettingsIntValue(settings, `${settingsId}__category`)) || undefined
    : undefined;
  const settingsLadder = withLadder
    ? init
      ? init.career_ladder_id || undefined
      : (settingsId && getSettingsIntValue(settings, `${settingsId}__ladder`)) || undefined
    : undefined;
  const settingsCountry = withCountry
    ? init
      ? init.country_id || undefined
      : (settingsId && getSettingsIntValue(settings, `${settingsId}__country`)) || undefined
    : undefined;
  const settingsState = withState
    ? init
      ? init?.state_id || undefined
      : (settingsId && getSettingsIntValue(settings, `${settingsId}__state`)) || undefined
    : undefined;
  const settingsLevel = withLevel
    ? init
      ? init?.management_level || undefined
      : settingsId ? sanitizeJobLevel(
          getSettingsIntValue(settings, `${settingsId}__level`), MANAGEMENT_LEVEL_FIRST - 1, MANAGEMENT_LEVEL_LAST
        ) : undefined
    : undefined;
  const settingsLevelMax = withLevel
    ? init
      ? init?.management_level_max || undefined
      : settingsId ? sanitizeJobLevel(
          getSettingsIntValue(settings, `${settingsId}__levelmax`), MANAGEMENT_LEVEL_FIRST - 1, MANAGEMENT_LEVEL_LAST
        ) : undefined
    : undefined;
  /* eslint-enable no-nested-ternary */

  const [scope, setScope] = useState<RoleScope | ''>(withScope && !resetScope && isValidRoleScope(settingsScope)
    ? settingsScope as RoleScope : '');
  const [openReqs, setOpenReqs] = useState(withOpenReqs && !resetOpenReqs ? Boolean(showOpenReqs || settingsOpenReqs) : false);
  const [supervisory, setSupervisory] = useState(withSupervisory ? Boolean(!resetSupervisory && settingsSupervisory) : false);
  const [matchRate, setMatchRate] = useState(withMatchRate && !resetMatchRate
    ? sanitizeMatchRate(settingsMatchRate) : DEFAULT_MATCH_RATE);
  const [level, setLevel] = useState(resetLevel || isNil(settingsLevel) ? MANAGEMENT_LEVEL_FIRST - 1 : settingsLevel);
  const [levelMax, setLevelMax] = useState(resetLevelMax || isNil(settingsLevelMax)
    ? MANAGEMENT_LEVEL_FIRST - 1 : settingsLevelMax);
  const [org, setOrg] = useState<number>(withOrg && !resetOrg ? sanitizeLookup(settingsOrg, orgs) : 0);
  const [category, setCategory] = useState<number>(withCategory && !resetCategory
    ? sanitizeLookup(settingsCategory, categories) : 0);
  const [ladder, setLadder] = useState<number>(withLadder && !resetLadder ? sanitizeLookup(settingsLadder, ladders) : 0);
  const [country, setCountry] = useState<number>(withCountry && !resetCountry ? sanitizeLookup(settingsCountry, countries) : 0);
  const [state, setState] = useState<number>(withState && !resetState ? sanitizeLookup(settingsState, states) : 0);

  const minLevelMax = level >= MANAGEMENT_LEVEL_LAST ? MANAGEMENT_LEVEL_LAST : level + 1;
  const levelMaxValue = level < MANAGEMENT_LEVEL_FIRST || level >= MANAGEMENT_LEVEL_LAST || levelMax < level
    ? minLevelMax - 1 : levelMax;

  useLayoutEffect(() => {
    if (parentShowOpenReqs) {
      setShowOpenReqs(true);
      setOpenReqs(true);
    }
  }, [parentShowOpenReqs]);

  useLayoutEffect(() => {
    if (resetFilters) {
      setResetScope(true);
      setScope('');
      setResetOpenReqs(true);
      setOpenReqs(false);
      setResetSupervisory(true);
      setSupervisory(false);
      setResetMatchRate(true);
      setMatchRate(DEFAULT_MATCH_RATE);
      setResetLevel(true);
      setResetLevelMax(true);
      setLevel(MANAGEMENT_LEVEL_FIRST - 1);
      setLevelMax(MANAGEMENT_LEVEL_FIRST - 1);
      setResetOrg(true);
      setOrg(0);
      setResetCategory(true);
      setCategory(0);
      setResetLadder(true);
      setLadder(0);
      setResetCountry(true);
      setCountry(0);
      setResetState(true);
      setState(0);
    }
  }, [resetFilters, DEFAULT_MATCH_RATE, MANAGEMENT_LEVEL_FIRST]);

  const isDomestic = useMemo(() => isDomesticCountryId(country, countries), [countries, country, isDomesticCountryId]);

  const local = domesticCountriesOnly || !isInternational;
  const disabled = parentDisabled || pendingSettingsUpdate || failedCountries;

  const handleScope = useCallback((value: string) => {
    const val = value === scope || !isValidRoleScope(value) ? '' : value as RoleScope;
    setResetScope(false);
    setScope(val);
    if (settingsId && settingsScope !== val) updateSettings?.({ [`${settingsId}__scope`]: val });
  }, [scope, settingsId, settingsScope, updateSettings]);

  const handleOpenReqs = useCallback((value: boolean) => {
    const val = Boolean(value);
    setResetOpenReqs(false);
    setOpenReqs(val);
    setShowOpenReqs(false);
    if (settingsId && Boolean(settingsOpenReqs) !== val) updateSettings?.({ [`${settingsId}__open_reqs`]: val });
  }, [settingsId, settingsOpenReqs, updateSettings]);

  const handleSupervisory = useCallback((value: boolean) => {
    const val = Boolean(value);
    setResetSupervisory(false);
    setSupervisory(val);
    if (settingsId && Boolean(settingsSupervisory) !== val) updateSettings?.({ [`${settingsId}__supervisory`]: val });
  }, [settingsId, settingsSupervisory, updateSettings]);

  const handleMatchRate = useCallback((value?: number) => {
    const val = sanitizeMatchRate(value);
    if (val) {
      setResetMatchRate(false);
      setMatchRate(val);
      if (settingsId && sanitizeMatchRate(settingsMatchRate) !== val) updateSettings?.({
        [`${settingsId}__match_rate`]: val
      });
    }
  }, [settingsId, settingsMatchRate, updateSettings]);

  const handleOrg = useCallback((value: number) => {
    const val = sanitizeLookup(value, orgs);
    setResetOrg(false);
    setOrg(val);
    if (settingsId && settingsOrg !== val) updateSettings?.({ [`${settingsId}__org`]: val });
  }, [orgs, settingsOrg, settingsId, updateSettings]);

  const handleCategory = useCallback((value: number) => {
    const val = sanitizeLookup(value, categories);
    setResetCategory(false);
    setCategory(val);
    if (settingsId && settingsCategory !== val) updateSettings?.({ [`${settingsId}__category`]: val });
  }, [categories, settingsCategory, settingsId, updateSettings]);

  const handleLadder = useCallback((value: number) => {
    const val = sanitizeLookup(value, ladders);
    setResetLadder(false);
    setLadder(val);
    if (settingsId && settingsLadder !== val) updateSettings?.({ [`${settingsId}__ladder`]: val });
  }, [ladders, settingsLadder, settingsId, updateSettings]);

  const handleCountry = useCallback((value: number) => {
    const val = sanitizeLookup(value, countries);
    setResetCountry(false);
    setCountry(val);
    if (settingsId && settingsCountry !== val) updateSettings?.({ [`${settingsId}__country`]: val });
  }, [countries, settingsCountry, settingsId, updateSettings]);

  const handleState = useCallback((value: number) => {
    const val = sanitizeLookup(value, states);
    setResetState(false);
    setState(val);
    if (settingsId && settingsState !== val) updateSettings?.({ [`${settingsId}__state`]: val });
  }, [states, settingsState, settingsId, updateSettings]);

  const handleLevel = useCallback((value: number) => {
    const val = sanitizeJobLevel(value, MANAGEMENT_LEVEL_FIRST - 1, MANAGEMENT_LEVEL_LAST) as number;
    setResetLevel(false);
    setResetLevelMax(false);
    setLevel(val);
    const maxLevel = val < MANAGEMENT_LEVEL_FIRST || val >= MANAGEMENT_LEVEL_LAST || levelMax <= val
      ? MANAGEMENT_LEVEL_FIRST - 1
      : null;
    if (!isNil(maxLevel)) setLevelMax(maxLevel);
    if (settingsId && settingsLevel !== val) updateSettings?.({
      [`${settingsId}__level`]: val,
      ...isNil(maxLevel) ? {} : { [`${settingsId}__levelmax`]: maxLevel }
    });
  }, [levelMax, settingsLevel, MANAGEMENT_LEVEL_FIRST, MANAGEMENT_LEVEL_LAST, settingsId, updateSettings]);

  const handleLevelMax = useCallback((value: number) => {
    const val = sanitizeJobLevel(value, level, MANAGEMENT_LEVEL_LAST) as number;
    setResetLevelMax(false);
    setLevelMax(val);
    if (settingsId && settingsLevelMax !== val) updateSettings?.({ [`${settingsId}__levelmax`]: val });
  }, [settingsLevelMax, level, MANAGEMENT_LEVEL_LAST, settingsId, updateSettings]);

  useEffect(() => {
    if (withOrg) getOrgs?.({ variables: { limit: MAX_LOOKUP_OPTIONS } });
  }, [withOrg, getOrgs]);

  useEffect(() => {
    if (withCategory) getCategories?.({ variables: { limit: MAX_LOOKUP_OPTIONS } });
  }, [withCategory, getCategories]);

  useEffect(() => {
    if (withLadder) getLadders?.({ variables: { limit: MAX_LOOKUP_OPTIONS } });
  }, [withLadder, getLadders]);

  useEffect(() => {
    if (withCountry || domesticCountriesOnly) getCountries?.({ variables: {
      ...local ? { local: true } : {},
      limit: MAX_COUNTRIES_OPTIONS
    } });
  }, [domesticCountriesOnly, local, withCountry, getCountries]);

  useEffect(() => {
    if (withState && isDomestic && country) getStates?.({ variables: {
      country_id: country,
      limit: MAX_COUNTRIES_OPTIONS
    } });
  }, [country, isDomestic, withState, getStates]);

  // eslint-disable-next-line complexity
  useLayoutEffect(() => {
    if ((init || settingsId) && settingsLoaded && !pendingSettingsUpdate) {
      if (withScope) {
        const scopeFromSettings = init
          ? init.scope || undefined
          : settingsId && getSettingsStrValue(settings, `${settingsId}__scope`);
        setScope(!resetScope && isValidRoleScope(scopeFromSettings) ? scopeFromSettings as RoleScope : '');
      }
      if (withOpenReqs) setOpenReqs(Boolean(!resetOpenReqs && (showOpenReqs || (init
        ? init.open_reqs_only
        : settingsId && getSettingsBoolValue(settings, `${settingsId}__open_reqs`)
      ))));
      if (withSupervisory) setSupervisory(Boolean(!resetSupervisory && (init
        ? init.supervisory_jobs_only
        : settingsId && getSettingsBoolValue(settings, `${settingsId}__supervisory`)
      )));
      if (withMatchRate) setMatchRate(resetMatchRate ? DEFAULT_MATCH_RATE
        : sanitizeMatchRate(init?.min_match_rate ||
            (settingsId ? getSettingsNumValue(settings, `${settingsId}__match_rate`) : undefined)
          ) || DEFAULT_MATCH_RATE
      );
      /* eslint-disable no-nested-ternary */
      if (withOrg) setOrg(sanitizeLookup(resetOrg ? 0 : init
          ? init.org_id
          : (settingsId && getSettingsIntValue(settings, `${settingsId}__org`)) || undefined,
        orgs));
      if (withCategory) setCategory(sanitizeLookup(resetCategory ? 0 : init
          ? init.job_category_id
          : (settingsId && getSettingsIntValue(settings, `${settingsId}__category`)) || undefined,
        categories));
      if (withLadder) setLadder(sanitizeLookup(resetLadder ? 0 : init
          ? init.career_ladder_id
          : (settingsId && getSettingsIntValue(settings, `${settingsId}__ladder`)) || undefined,
        ladders));
      if (withCountry) setCountry(sanitizeLookup(resetCountry ? 0 : init
          ? init.country_id || 0
          : (settingsId && getSettingsIntValue(settings, `${settingsId}__country`)) || undefined,
        countries));
      if (withState) setState(sanitizeLookup(resetState ? 0 : init
          ? init.state_id || 0
          : (settingsId && getSettingsIntValue(settings, `${settingsId}__state`)) || undefined,
        states));
      if (withLevel) {
        const levelMin = resetLevel || (init && isNil(init.management_level))
          ? MANAGEMENT_LEVEL_FIRST - 1
          : sanitizeJobLevel(
            init
              ? init.management_level
              : settingsId ? getSettingsIntValue(settings, `${settingsId}__level`) : undefined,
            MANAGEMENT_LEVEL_FIRST - 1,
            MANAGEMENT_LEVEL_LAST
          );
        const maxLevel = resetLevelMax || (init && isNil(init.management_level_max))
          ? MANAGEMENT_LEVEL_FIRST - 1
          : sanitizeJobLevel(
            init
              ? init.management_level_max
              : settingsId ? getSettingsIntValue(settings, `${settingsId}__levelmax`) : undefined,
            MANAGEMENT_LEVEL_FIRST - 1,
            MANAGEMENT_LEVEL_LAST
          );
        if (!isNil(levelMin)) setLevel(levelMin);
        if (!isNil(maxLevel)) setLevelMax(maxLevel);
      }
      /* eslint-enable no-nested-ternary */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // ignoring `showOpenReqs` and `reset*` changes:
    settingsLoaded, pendingSettingsUpdate, orgs, categories, ladders, countries, states, settings, init,
    settingsId, withOpenReqs, withLevel, withScope, withSupervisory, withMatchRate,
    withOrg, withCategory, withLadder, withCountry, withState,
    DEFAULT_MATCH_RATE, MANAGEMENT_LEVEL_FIRST, MANAGEMENT_LEVEL_LAST
  ]);

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (settingsLoaded && countries && orgs && categories && ladders && onChange && matchRate) {
      const minLevel = level < MANAGEMENT_LEVEL_FIRST ? null : level;
      const maxLevel = levelMax <= level ? null : levelMax;
      const newParams: JobsIndexFilterValues = {
        ...withOpenReqs ? { open_reqs_only: openReqs } : {},
        ...withLevel && (isNil(minLevel) || isNil(maxLevel)) ? { management_level: minLevel } : {},
        ...withLevel && !isNil(minLevel) && !isNil(maxLevel) ? {
          management_level_min: minLevel,
          management_level_max: maxLevel
        } : {},
        ...withCountry || domesticCountriesOnly ? {
          country_id: domesticCountriesOnly ? getDomesticCountryId(countries) : country
        } : {},
        ...withState && isDomestic ? { state_id: state } : {},
        ...withScope && scope ? { scope } : {},
        ...withOrg ? { org_id: org } : {},
        ...withCategory ? { job_category_id: category } : {},
        ...withLadder ? { career_ladder_id: ladder } : {},
        ...withMatchRate ? { min_match_rate: matchRate } : {},
        ...withSupervisory ? { supervisory_jobs_only: supervisory } : {}
      };
      onChange(newParams);
    }
  }, [
    scope, openReqs, supervisory, matchRate, org, category, ladder, country, state, level, levelMax,
    onChange, settingsLoaded, orgs, categories, ladders, countries, isDomestic,
    withCategory, withCountry, withLadder, withLevel, withMatchRate, withOpenReqs, withOrg,
    withScope, withState, withSupervisory, domesticCountriesOnly,
    getDomesticCountryId, MANAGEMENT_LEVEL_FIRST
  ]);

  if (!visible) return null;

  const orgUnit = withOrg && (!orgs || size(orgs) >= 1) ? (
    <Box pr={1.25} pb={1} display="flex" justifyContent="flex-end">
      <FilterSelector
          type="org"
          choices={orgs}
          value={org}
          onChange={handleOrg}
          disabled={disabled}
      />
    </Box>
  ) : null;

  const jobCategory = withCategory && (!categories || size(categories) >= 1) ? (
    <Box pr={1.25} pb={1} display="flex" justifyContent="flex-end">
      <FilterSelector
          type="job_category"
          choices={categories}
          value={category}
          onChange={handleCategory}
          disabled={disabled}
      />
    </Box>
  ) : null;

  const careerLadder = withLadder && (!ladders || size(ladders) >= 1) ? (
    <Box pr={1.25} pb={1} display="flex" justifyContent="flex-end">
      <FilterSelector
          type="career_ladder"
          choices={ladders}
          value={ladder}
          onChange={handleLadder}
          disabled={disabled}
      />
    </Box>
  ) : null;

  const dropdowns = (
    <>
      {orgUnit}
      {jobCategory}
      {careerLadder}
      {withCountry ? (
        <>
          <Box pr={1.25} pb={1} display="flex" justifyContent="flex-end">
            <FilterSelector
                type="country"
                choices={countries}
                value={country}
                onChange={handleCountry}
                disabled={disabled}
            />
          </Box>
          {withState ? (
            <Box pr={1.25} pb={1} display="flex" justifyContent="flex-end">
              <FilterSelector
                  type="state"
                  choices={isDomestic ? states : undefined}
                  value={isDomestic ? state : 0}
                  onChange={handleState}
                  disabled={disabled || !isDomestic}
              />
            </Box>
          ) : undefined}
        </>
      ) : undefined}
      {withLevel ? (
        <Box pb={1} display="flex" justifyContent="flex-end" alignItems="center" flexWrap="nowrap" color="text.secondary">
          <Box display="flex" justifyContent="flex-end">
            <LevelSelector
                variant="job"
                search
                minValue={MANAGEMENT_LEVEL_FIRST}
                maxValue={MANAGEMENT_LEVEL_LAST}
                value={level}
                onChange={handleLevel}
                disabled={disabled ? true : undefined}
            />
          </Box>
          <RemoveIcon color="inherit"/>
          <Box display="flex" justifyContent="flex-end">
            <LevelSelector
                variant="job_max"
                search
                minValue={minLevelMax}
                maxValue={MANAGEMENT_LEVEL_LAST}
                value={levelMaxValue}
                onChange={handleLevelMax}
                disabled={disabled || level < MANAGEMENT_LEVEL_FIRST || level >= MANAGEMENT_LEVEL_LAST ? true : undefined}
            />
          </Box>
        </Box>
      ) : undefined}
    </>
  );

  return (
    <Box
        ref={ref}
        flexGrow={1}
        display="flex"
        flexWrap="wrap"
        alignItems="flex-start"
        justifyContent="space-between"
    >
      {withScope ? (
        <Box py={0.5} pr={1.25} display="flex">
          <ScopeSelector
              scope={ROLE_SCOPES}
              value={scope}
              onChange={handleScope}
              disabled={disabled ? true : undefined}
          />
        </Box>
      ) : undefined}
      {dropdownsFirst ? dropdowns : undefined}
      {withOpenReqs ? (
        <Box
            pl={3.25}
            pt={1}
            pb={0.5}
            display="flex"
            alignItems="flex-start"
            justifyContent="flex"
            className={openReqsFilter}
        >
          <OnOffSwitch
              label="jobs_index.open_reqs_only"
              value={openReqs}
              onChange={handleOpenReqs}
              disabled={disabled ? true : undefined}
          />
        </Box>
      ) : undefined}
      {withSupervisory ? (
        <Box pl={1} pt={1} pb={0.5} display="flex" justifyContent="center" className={supervisoryFilter}>
          <OnOffSwitch
              label="jobs_index.supervisory_only"
              value={supervisory}
              onChange={handleSupervisory}
              disabled={disabled ? true : undefined}
          />
        </Box>
      ) : undefined}
      {withMatchRate ? (
        <Box flexGrow={3} pl={1} pr={3.25} py={0.5} display="flex" className={matchRateFilter}>
          <MatchRateSlider
              value={matchRate}
              onChange={handleMatchRate}
              disabled={disabled ? true : undefined}
          />
        </Box>
      ) : undefined}
      {dropdownsFirst ? undefined : (
        <Box
            flexGrow={1}
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="flex-end"
        >
          {dropdowns}
        </Box>
      )}
      {onExport ? (
        <Box pl={1} pt={0.5} pb={1.5} alignSelf="center">
          <ExportButton
              pending={exportPending}
              disabled={disabled || exportDisabled}
              onExport={onExport}
          />
        </Box>
      ) : undefined}
    </Box>
  );
});

JobsIndexFilters.displayName = JOBS_FILTERS_DISPLAY_NAME;

JobsIndexFilters.propTypes = JobsIndexFiltersPropTypes;

const JobsIndexFiltersMemo = memo(JobsIndexFilters);

JobsIndexFiltersMemo.displayName = JOBS_FILTERS_DISPLAY_NAME;

export default JobsIndexFiltersMemo;

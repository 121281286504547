import { forwardRef, memo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
// Material UI imports
import Grid from '@mui/material/Grid';
// EmPath UI Components
import FilterSelector from '@empathco/ui-components/src/elements/FilterSelector';
import LevelSelector from '@empathco/ui-components/src/elements/LevelSelector';
// local imports
import { LookupItem } from '../models/lookupItem';
import { SkillLevel } from '../models/skill';
import { Manager } from '../models/manager';
import ChainOfCommandSelector from '../v3/ChainOfCommandSelector';

type EmployeesWithSkillFiltersProps = {
  withHierarchy?: boolean | null;
  withLevel?: boolean | null;
  withLevelSearch?: boolean | null;
  uid?: string | null;
  me?: string | null;
  leader?: string | null;
  countries?: LookupItem[] | null;
  country?: number;
  states?: LookupItem[] | null;
  state?: number;
  managers?: Manager[] | null;
  manager?: string;
  level?: SkillLevel;
  setCountry: (id: number) => void;
  setState: (id: number) => void;
  setManager: (id: string, hasTeams?: boolean) => void;
  setLevel: (level: SkillLevel) => void;
  disabled?: boolean | null;
  disabledState?: boolean | null;
}

const EmployeesWithSkillFiltersPropTypes = {
  // attributes
  withHierarchy: PropTypes.bool,
  withLevel: PropTypes.bool,
  withLevelSearch: PropTypes.bool,
  uid: PropTypes.string,
  me: PropTypes.string,
  leader: PropTypes.string,
  countries: PropTypes.array,
  country: PropTypes.number,
  states: PropTypes.array,
  state: PropTypes.number,
  managers: PropTypes.array,
  manager: PropTypes.string,
  level: PropTypes.number as Validator<SkillLevel>,
  setCountry: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
  setManager: PropTypes.func.isRequired,
  setLevel: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  disabledState: PropTypes.bool
};

const EmployeesWithSkillFilters = forwardRef<HTMLDivElement, EmployeesWithSkillFiltersProps>(({
  withHierarchy = false,
  withLevel = false,
  withLevelSearch = false,
  uid,
  me,
  leader,
  countries,
  country = 0,
  setCountry,
  states,
  state = 0,
  setState,
  managers,
  manager = '0',
  setManager,
  level,
  setLevel,
  disabled = false,
  disabledState = false
}, ref) => (
  <Grid ref={ref} container spacing={2} wrap="wrap">
    <Grid item xs="auto">
      <FilterSelector
          type="country"
          choices={countries}
          value={country}
          onChange={setCountry}
          disabled={disabled}
      />
    </Grid>
    <Grid item xs="auto">
      <FilterSelector
          type="state"
          choices={disabledState ? undefined : states}
          value={disabledState ? 0 : state}
          onChange={setState}
          disabled={disabled || disabledState}
      />
    </Grid>
    {withLevel ? (
      <Grid item xs="auto">
        <LevelSelector
            search={withLevelSearch ? true : undefined}
            value={level}
            onChange={setLevel as (level: number) => void}
            disabled={disabled ? true : undefined}
        />
      </Grid>
    ) : undefined}
    <Grid item container xs justifyContent="flex-end">
      <ChainOfCommandSelector
          uid={uid}
          me={withHierarchy ? me : undefined}
          leader={withHierarchy ? leader : undefined}
          expandable={withHierarchy}
          managers={managers}
          value={manager}
          onChange={setManager}
          disabled={disabled}
      />
    </Grid>
  </Grid>
));

EmployeesWithSkillFilters.displayName = 'EmployeesWithSkillFilters';

EmployeesWithSkillFilters.propTypes = EmployeesWithSkillFiltersPropTypes;

export default memo(EmployeesWithSkillFilters);

import {
  createContext, useState, useLayoutEffect, useCallback,
  type FunctionComponent, type ReactNode, type SetStateAction, type Dispatch
} from 'react';
import PropTypes, { type Validator } from 'prop-types';
import size from 'lodash/size';
import slice from 'lodash/slice';
import isEqual from 'lodash/isEqual';
import findIndex from 'lodash/findIndex';
// local imports
import { Skill } from '../models/skill';
import { Manager } from '../models/manager';
import { transformHierarchy } from './supervisor';

export type SkillGroupType = 'job' | 'org';
export const SkillGroupTypePropType = PropTypes.oneOf(['job', 'org']);

export type SkillGroup = {
  id: number;
  type: SkillGroupType;
  title: string;
  skills: Skill[];
}

export type PersistentStateType = {
  // Talent Finder
  skills: Skill[]; // individual skills
  setSkills: Dispatch<SetStateAction<Skill[]>>;
  skillGroups: SkillGroup[]; // skills by job/org
  setSkillGroups: Dispatch<SetStateAction<SkillGroup[]>>;
  desiredSkillIds: number[]; // optional skills
  setDesiredSkillIds: Dispatch<SetStateAction<number[]>>;
  // Hierarchy filtered by Leader:
  leaderOrg?: Manager[];
  updateLeaderOrg: (hierarchy: Manager[], leaderId: string) => void;
};

export const PersistentStatePropTypes = PropTypes.shape({
  // Talent Finder
  skills: PropTypes.arrayOf(PropTypes.object.isRequired as Validator<Skill>).isRequired,
  setSkills: PropTypes.func.isRequired,
  skillGroups: PropTypes.arrayOf(PropTypes.object.isRequired as Validator<SkillGroup>).isRequired,
  setSkillGroups: PropTypes.func.isRequired,
  desiredSkillIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  setDesiredSkillIds: PropTypes.func.isRequired,
  // Hierarchy filtered by Leader:
  leaderOrg: PropTypes.arrayOf(PropTypes.object.isRequired) as Validator<Manager[]>,
  updateLeaderOrg: PropTypes.func.isRequired
});

export const PersistentContext = createContext<PersistentStateType>({
  skills: [],
  setSkills: () => null,
  skillGroups: [],
  setSkillGroups: () => null,
  desiredSkillIds: [],
  setDesiredSkillIds: () => null,
  leaderOrg: undefined,
  updateLeaderOrg: (_hierarchy: Manager[], _leaderId: string) => null
});

type PersistentProviderProps = {
  children?: ReactNode | ReactNode[];
  state?: PersistentStateType;
};

const PersistentProviderPropTypes = {
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  // for Storybook only
  state: PersistentStatePropTypes
};

const NO_MANAGERS = [] as Manager[];

export const getLeaderOrg = (rootUid: string, leaderOrg?: Manager[] | null): Manager[] | null =>
  leaderOrg && rootUid && (size(leaderOrg) < 1 || leaderOrg?.[0]?.topId === rootUid) ? leaderOrg : null;

export const PersistentProvider: FunctionComponent<PersistentProviderProps> = ({
  state,
  children
}) => {
  const [skills, setSkills] = useState<Skill[]>(state?.skills ?? []);
  const [skillGroups, setSkillGroups] = useState<SkillGroup[]>(state?.skillGroups ?? []);
  const [desiredSkillIds, setDesiredSkillIds] = useState<number[]>(state?.desiredSkillIds ?? []);
  const [leaderOrg, setLeaderOrg] = useState<Manager[] | undefined>(state?.leaderOrg);

  const updateLeaderOrg = useCallback((hierarchy: Manager[], leaderId: string) => setLeaderOrg((prevLeaderOrg) => {
    if (prevLeaderOrg?.[0]?.topId === leaderId) return prevLeaderOrg;
    const { managers } = hierarchy?.[0] || {};
    if (!managers) return hierarchy;
    const leaderIdx = findIndex(managers, ['code', leaderId]);
    if (leaderIdx < 0) return NO_MANAGERS;
    const leaderLevel = managers[leaderIdx].level || 1;
    let nextLeaderId = findIndex(managers, ({ level }) => (level || 0) <= leaderLevel, leaderIdx + 1) + 1;
    if (nextLeaderId <= 0) nextLeaderId = size(managers);
    return transformHierarchy(slice(managers, leaderIdx, nextLeaderId));
  }), []);

  const [context, setContext] = useState<PersistentStateType>({
    skills, setSkills,
    skillGroups, setSkillGroups,
    desiredSkillIds, setDesiredSkillIds,
    leaderOrg, updateLeaderOrg
  });

  useLayoutEffect(() => {
    if (
      context.skills !== skills ||
      context.skillGroups !== skillGroups ||
      context.leaderOrg !== leaderOrg ||
      !isEqual(context.desiredSkillIds, desiredSkillIds)
    ) {
      setContext({
        skills, setSkills,
        skillGroups, setSkillGroups,
        desiredSkillIds, setDesiredSkillIds,
        leaderOrg, updateLeaderOrg
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // do not need anything to do when `context` is changed:
    skills, skillGroups, desiredSkillIds, leaderOrg, updateLeaderOrg
  ]);

  return (
    <PersistentContext.Provider value={context}>
      {children}
    </PersistentContext.Provider>
  );
};

PersistentProvider.propTypes = PersistentProviderPropTypes;

import {
  memo, useContext, useState, useEffect, useCallback, useMemo, type FunctionComponent, type ReactNode, useReducer
} from 'react';
import PropTypes from 'prop-types';
import toSafeInteger from 'lodash/toSafeInteger';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import isPlainObject from 'lodash/isPlainObject';
import pick from 'lodash/pick';
import { useApolloClient } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
// Material Icon imports
import CreateIcon from '@mui/icons-material/Create';
// EmPath UI Components
import { bold, mapChunks } from '@empathco/ui-components/src/helpers/intl';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
import TargetIcon from '@empathco/ui-components/src/elements/TargetIcon';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardFooter from '@empathco/ui-components/src/elements/CardFooter';
import LevelSelector from '@empathco/ui-components/src/elements/LevelSelector';
import ActionSucceededMessage from '@empathco/ui-components/src/elements/ActionSucceededMessage';
import SkillTrendIcon from '@empathco/ui-components/src/elements/SkillTrendIcon';
import LevelDescription from '@empathco/ui-components/src/elements/LevelDescription';
// local imports
import { Skill, SKILL_LEVEL_FIRST, SKILL_LEVEL_TO_MENTOR } from '../models/skill';
import { Preferences } from '../models/preferences';
import useCustomerSettings from '../config/customer';
import {
  PREF_SKILL_FIELDS, TARGET_SKILL_FIELDS,
  getSkillCurrentLevel, getSkillCurrentLevelOnly, hasCurrentLevel, updateCachedIsTarget
} from '../helpers/models';
import { toggleReducer } from '../helpers/reducers';
import { DataContext } from '../context';
import LevelDescriptionsAccordion from '../elements/LevelDescriptionsAccordion';
import TargetSwitch from '../v3/TargetSwitch';
// SCSS imports
import { targetIcon, topSection, foldingButton, coursesButton, confirmButton } from './SkillDetails.module.scss';

type SkillDetailsProps = {
  isEmployee?: boolean;
  supervisor?: boolean;
  reducedUI?: boolean;
  testUnfolded?: boolean;
}

const SkillDetailsPropTypes = {
  isEmployee: PropTypes.bool,
  supervisor: PropTypes.bool,
  reducedUI: PropTypes.bool,
  testUnfolded: PropTypes.bool
};

// TODO: refactor this component to eliminate `eslint-disable-next-line` directives:
// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const SkillDetails: FunctionComponent<SkillDetailsProps> = ({
  isEmployee = false,
  supervisor = false,
  reducedUI = false,
  testUnfolded = false
}) => {
  const { getSkillCoursesUrl, HAS_MENTORING } = useCustomerSettings();
  const { cache } = useApolloClient();

  const {
    skill: { data: skill, pending, failed },
    skillUpdate: { pending: updatePending, failed: updateFailed, params: updateParams }, updateSkill,
    targetSkillUpdate: { pending: targetPending, failed: targetFailed, params: targetParams }, updateTargetSkill,
    preferences: { data: preferences, pending: preferencesPending, failed: preferencesFailed }, requirePreferences
  } = useContext(DataContext);
  const loadingPreferences = preferencesPending || !preferences;
  const { mentor_others } = (loadingPreferences || preferencesFailed ? {} : preferences) as Preferences;

  useEffect(() => {
    if (isEmployee) requirePreferences?.();
  }, [requirePreferences, isEmployee, supervisor]);

  const {
    id: skill_id, title, abbr, description, level_description, activities,
    inferred_level, is_target, is_mentoring, is_in_demand // , is_inference, can_mentor
  } = skill || {};
  const currentLevel = isEmployee ? getSkillCurrentLevel(skill) : SKILL_LEVEL_FIRST;
  const currentOnlyLevel = getSkillCurrentLevelOnly(skill);
  const hasInference = isEmployee && !isNil(inferred_level) && inferred_level >= SKILL_LEVEL_FIRST;
  const isMentoring = HAS_MENTORING && Boolean(is_mentoring);
  const isTarget = Boolean(is_target);
  const pleLink = useMemo(() => getSkillCoursesUrl({ title, abbr }), [abbr, title, getSkillCoursesUrl]);

  const switchTargetSkill = useCallback((value: boolean) => {
    if (skill && skill.id && Boolean(skill.is_target) !== Boolean(value)) updateTargetSkill?.({
      skill_id: skill.id,
      is_target: value,
      targetSkill: pick(updateCachedIsTarget(skill, value), TARGET_SKILL_FIELDS)
    });
  }, [skill, updateTargetSkill]);

  const [level, setLevel] = useState(currentLevel);
  useEffect(() => {
    setLevel(currentLevel);
  }, [skill_id, currentLevel]);

  const canMentorThis = HAS_MENTORING && !reducedUI && level >= SKILL_LEVEL_TO_MENTOR; // || Boolean(can_mentor)
  const canMentor = Boolean(mentor_others) && canMentorThis;
  const changed = level !== currentLevel || level !== currentOnlyLevel || !hasCurrentLevel(skill);

  const switchMentoring = useCallback((value: boolean) => {
    if (HAS_MENTORING && skill?.id && Boolean(skill.is_mentoring) !== Boolean(canMentor && value)) updateSkill?.({
      apolloCache: cache,
      skill_id: skill.id,
      level,
      is_opt_in_mentor: canMentor && value,
      skill: pick(skill, PREF_SKILL_FIELDS) as Skill,
      reason: changed ? 'level' : 'mentoring',
      source: 'skill'
    });
  }, [canMentor, skill, level, changed, updateSkill, cache, HAS_MENTORING]);

  const handleConfirm = useCallback(() => {
    if (skill) updateSkill?.({
      apolloCache: cache,
      skill_id: skill.id,
      level,
      ...HAS_MENTORING ? { is_opt_in_mentor: canMentor && isMentoring } : {},
      skill: pick(skill, PREF_SKILL_FIELDS) as Skill,
      source: 'skill'
    });
  }, [canMentor, skill, level, isMentoring, updateSkill, cache, HAS_MENTORING]);

  const [folded, toggleFolded] = useReducer(toggleReducer, !testUnfolded);

  const boldLink = useCallback((chunks?: ReactNode | ReactNode[] | null): ReactNode => (
    <StandardLink bold dark onClick={toggleFolded}>
      {mapChunks(chunks)}
    </StandardLink>
  ), [toggleFolded]);

  const loading = pending || !skill;
  const updatedThis = updateParams && isPlainObject(updateParams) &&
    updateParams.skill_id >= 1 && updateParams.skill_id === skill_id;
  const levelUpdate = updateParams?.reason !== 'mentoring';

  return (
    <>
      <ContentCard>
        <CardTitle
            title={
              (failed && 'skill.default_skill_title') ||
              (loading && <Skeleton variant="text" width="12rem"/>) ||
              title || 'skill.default_skill_title'
            }
            disabled={failed || loading}
            subheader={!failed && !loading && !reducedUI ? (
              <>
                {isEmployee ? (
                  <TargetIcon
                      // button
                      active={isTarget}
                      tooltip={isTarget && !targetPending ? 'skill.target_this' : undefined}
                      tooltipPlacement="top"
                      // onClick={switchTargetSkill}
                      // disabled={targetPending}
                      pending={targetPending}
                      className={targetIcon}
                  />
                ) : undefined}
                <SkillTrendIcon active={is_in_demand}/>
              </>
            ) : undefined}
            action={!failed && !loading && isEmployee
              ? (reducedUI && pleLink && (
                <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    href={pleLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={coursesButton}
                >
                  <FormattedMessage id="skill.view_related_courses"/>
                </Button>
              )) || (!reducedUI && (
                <TargetSwitch
                    variant="skill"
                    value={isTarget}
                    onChange={switchTargetSkill}
                    disabled={targetPending}
                    pendingValue={targetPending ? !isTarget : undefined}
                />
              ))
            : undefined}
            withDivider={failed ? true : undefined}
        />
        {(failed && (
          <Alert severity="info" variant="standard">
            <FormattedMessage id="skill.not_found"/>
          </Alert>
        )) ||
        (loading && <LoadingPlaceholder flat/>) || (
          <>
            <CardSection compact={isEmployee || supervisor} bottom={!isEmployee && !supervisor} className={topSection}>
              <Box display="flex" alignItems="flex-end">
                <BoxTypography pt={isEmployee ? 1.5 : undefined} variant="h5">
                  <FormattedMessage id="skill.description"/>
                </BoxTypography>
                {isEmployee && (loadingPreferences || canMentor) && canMentorThis ? (
                  <Box
                      flexGrow={1}
                      display="flex"
                      alignItems="center"
                      flexWrap="wrap"
                      justifyContent="flex-end"
                  >
                    {(loadingPreferences && <Skeleton variant="text" width="10rem"/>) || (
                      <TargetSwitch
                          variant="advisor"
                          value={isMentoring}
                          onChange={switchMentoring}
                          disabled={updatePending}
                          pendingValue={updatePending && updateParams?.reason ? !isMentoring : undefined}
                      />
                    )}
                  </Box>
                ) : undefined}
              </Box>
              <Box pt={1.5}>
                {description}
              </Box>
            </CardSection>
            {isEmployee ? (
              <CardSection>
                {hasInference ? (
                  <Typography variant="body1" paragraph>
                    <FormattedMessage
                        id="skill.inferred_level"
                        values={{
                          bold,
                          level: (
                            <FormattedMessage
                                id="common.skill_level.description"
                                values={{ level: toSafeInteger(inferred_level) }}
                            />
                          )
                        }}
                    />
                  </Typography>
                ) : undefined}
                <Typography variant="body1" paragraph>
                  <FormattedMessage
                      id="skill.current_level"
                      values={{
                        bold: boldLink,
                        level: (
                          <FormattedMessage
                              id="common.skill_level.description"
                              values={{
                                level: hasInference
                                  ? (isNil(currentOnlyLevel) && 'null') || currentOnlyLevel
                                  : toSafeInteger(currentOnlyLevel)
                                }}
                          />
                        )
                      }}
                  />
                  <Button
                      disableElevation
                      onClick={toggleFolded}
                      variant="contained"
                      color="primary"
                      aria-label="unfold"
                      className={foldingButton}
                  >
                    <CreateIcon/>
                  </Button>
                </Typography>
              </CardSection>
            ) : supervisor && (
              <LevelDescriptionsAccordion
                  activities={activities}
                  levelDescriptions={level_description}
                  disabled={failed || loading}
              />
            )}
            {isEmployee ? (
              <Collapse in={!folded}>
                <CardSection compact>
                  <Typography paragraph>
                    <FormattedMessage
                        id="skill.level_info"
                        values={{ inferred: hasInference }}
                    />
                  </Typography>
                  <Box pb={2.5}>
                    <LevelSelector
                        value={level}
                        onChange={setLevel as (level: number) => void}
                        disabled={updatePending ? true : undefined}
                    />
                  </Box>
                  {isArray(level_description) && isString(level_description[level]) ? (
                    <LevelDescription description={level_description[level]}/>
                  ) : undefined}
                </CardSection>
                <CardFooter withDivider>
                  <Button
                      onClick={handleConfirm}
                      color="primary"
                      variant="contained"
                      disableElevation
                      disabled={!changed || updatePending ? true : undefined}
                      startIcon={updatePending && !updateParams?.reason
                        ? <CircularProgress size={18} color="inherit"/> : undefined}
                      className={confirmButton}
                  >
                    <FormattedMessage id="common.button.save"/>
                  </Button>
                </CardFooter>
              </Collapse>
            ) : undefined}
          </>
        )}
      </ContentCard>
      <ActionFailedAlert
          message="error.fetch_failed"
          open={preferencesFailed}
      />
      <ActionFailedAlert
          message="skill.target_update_error"
          open={targetFailed &&
              targetParams &&
              isPlainObject(targetParams) &&
              targetParams.skill_id >= 1 &&
              targetParams.skill_id === skill_id
            ? true : undefined}
      />
      <ActionFailedAlert
          message="skill.level_update_error"
          open={updateFailed && updatedThis && levelUpdate ? true : undefined}
      />
      <ActionFailedAlert
          message="skill.advisor_update_error"
          open={updateFailed && updatedThis && !levelUpdate ? true : undefined}
      />
      <ActionSucceededMessage
          title="skill.level_update_succeeded.title"
          message="skill.level_update_succeeded.message"
          open={updatePending === false && updateFailed === false && updatedThis && levelUpdate ? true : undefined}
      />
    </>
  );
};

SkillDetails.propTypes = SkillDetailsPropTypes;

export default memo(SkillDetails);

import { gql } from '@apollo/client';
// local imports
import { API_ADMIN_REPORT } from '../config/api';

export const ADMIN_USER_ENGAGEMENT_QUERY = gql`
  query AdminReportUserEngagement(
    $report_id: Int!
    $offset: Int
    $limit: Int
  ) {
    adminReportUserEngagement(
      report_id: $report_id,
      offset: $offset,
      limit: $limit
    )
    @rest(
      type: "AdminReportUserEngagementPayload",
      path: "${API_ADMIN_REPORT}?limit={args.limit}&offset={args.offset}"
    ) {
      count
      total_created
      total_logged_in
      results {
        day
        created
        logged_in
      }
    }
  }
`;

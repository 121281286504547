import { memo, type FunctionComponent, type Dispatch, type SetStateAction } from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FormattedMessage } from 'react-intl';
// EmPath UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
// local imports
import { Skill } from '../models/skill';
import { MIN_JOB_SKILLS, MAX_JOB_SKILLS } from '../config/params';
import SkillsDnDSelector from '../widgets/SkillsDnDSelector';

type EditJobSkillsProps = {
  allSkills: Skill[]; // union of top/current skills + added skills
  skillIds: number[]; // selected skill ids
  setSkillIds: Dispatch<SetStateAction<number[]>>;
  userAddedSkillIds: number[];
  onAddedSkillsChange: (addedSkillIds: number[]) => void;
  onSelectionChange: (skillIds: number[]) => void;
  onReset?: () => void;
  disabled?: boolean | null;
  disabledAddSkill?: boolean | null;
  title?: string;
  warning?: string;
}

const EditJobSkillsPropTypes = {
  allSkills: PropTypes.array.isRequired,
  skillIds: PropTypes.array.isRequired,
  setSkillIds: PropTypes.func.isRequired,
  userAddedSkillIds: PropTypes.array.isRequired,
  onAddedSkillsChange: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  disabled: PropTypes.bool,
  disabledAddSkill: PropTypes.bool,
  title: PropTypes.string,
  warning: PropTypes.string
};

const EditJobSkills: FunctionComponent<EditJobSkillsProps> = ({
  allSkills,
  skillIds,
  setSkillIds,
  userAddedSkillIds,
  onAddedSkillsChange,
  onSelectionChange,
  onReset,
  disabled = false,
  disabledAddSkill = false,
  title,
  warning
}) => {
  const selected = size(skillIds);
  return (
    <>
      <BoxTypography pt={4} px={3.5} pb={1.75} variant="subtitle1">
        <FormattedMessage
            id="hr.job_skills.suggestions"
            values={{ role: title }}
        />
      </BoxTypography>
      <DndProvider backend={HTML5Backend} context={window}>
        <SkillsDnDSelector
            variant="job"
            allSkills={allSkills as Skill[]}
            skillIds={skillIds}
            setSkillIds={setSkillIds}
            userAddedSkillIds={userAddedSkillIds}
            onAddedSkillsChange={onAddedSkillsChange}
            onSelectionChange={onSelectionChange}
            onReset={onReset}
            disabledSkills={disabled || selected >= MAX_JOB_SKILLS}
            disabledSelected={disabled}
            disabledAddSkill={disabledAddSkill}
            title="hr.job_skills.dnd.title"
            subtitle="hr.job_skills.dnd.subtitle"
            warning={warning}
            values={warning ? {
              count: MIN_JOB_SKILLS - selected,
              min: MIN_JOB_SKILLS,
              max: MAX_JOB_SKILLS
            } : undefined}
        />
      </DndProvider>
    </>
  );
};

EditJobSkills.propTypes = EditJobSkillsPropTypes;

export default memo(EditJobSkills);

/* eslint-disable max-lines */
import { type ApolloCache } from '@apollo/client';
// local imports
import { ActionState, ContextEntity, ContextEntityWithCount, ContextObject } from '../models/contextEntity';
import { JobStatusObject } from '../models/jobStatusObject';
import { DataStatusObject } from '../models/dataStatusObject';
import { Skill, SkillLevel } from '../models/skill';
import { Job } from '../models/job';
import { OpenReq } from '../models/openReq';
import { DevPlan } from '../models/devPlan';
import { Preferences } from '../models/preferences';
import { Settings } from '../models/settings';
import { Manager } from '../models/manager';
import { BoardSkills, BoardSuggested, BoardJobs } from '../models/board';
import { MatchRateObject } from '../models/matchRateObject';
import { SkillEmployees } from '../models/skillEmployees';
import { Employee } from '../models/employee';
import { JobMoves } from '../models/jobMoves';
import { INotification } from '../models/notification';
import { UnreadCountObject } from '../models/unreadCountObject';
import { TalentDataObject } from '../models/talentData';
import { CareerPath } from '../models/careerPath';
import { BuilderScope, BuilderStep } from '../constants/builder';
import { EmployeeManagementLevel } from '../constants/managementLevel';
import { JobSort, JobSortDirections } from '../constants/jobSort';
import { SkillSort, SkillSortDirections } from '../constants/skillsSort';
import { RoleScope } from '../constants/scopes';
import { EmailNotificationType } from '../constants/emailNotifications';
import { CommonActions, FETCHING, FETCHED, PARAMS } from '../constants/actionTypes';
import { SkillUpdateSource } from '../constants/mixpanel';
import { PaginationParams } from './commonParams';

export type DataObjectId =
  // USER
  'preferences' |
  'settings' |
  'unreadCount' |
  // SKILLS
  'boardSkills' |
  'boardSuggested' |
  'boardJobs' |
  'skillsGap' |
  // SKILL
  'skill' |
  'devPlan' |
  'skillEmployees' |
  // ROLE
  'role' |
  'matchRate' |
  'jobMoves' |
  // EDITABLE SKILLS
  'skl' |
  // DATA STATUS
  'jobStatus' |
  'dataStatus';

export interface SkillLevelData {
  skill_id: number;
  level: number;
  is_opt_in_mentor?: boolean;
  is_target?: boolean;
}

export interface SkillParams {
  skill_id: string;
}

export interface SkillDetailsParams {
  skill_id: string;
  hrbp?: boolean;
}

export interface DevPlanParams {
  skill_id: string;
  provider_id?: number | null;
}

export interface LearningProgressParams {
  duration?: number;
}

export interface JobsIndexParams /* extends LocationParams */ {
  scope?: RoleScope | null;
  job_category_id?: number | null;
  career_ladder_id?: number | null;
  org_id?: number | null;
  open_reqs_only?: boolean | null;
  supervisory_jobs_only?: boolean | null;
  min_match_rate?: number | null;
  country_id?: number | null;
  state_id?: number | null;
  location_id?: number | null;
  management_level?: EmployeeManagementLevel | null;
  management_level_min?: EmployeeManagementLevel | null;
  management_level_max?: EmployeeManagementLevel | null;
  sort_by?: JobSort | null;
  direction?: JobSortDirections | null;
  limit?: number | null;
  offset?: number | null;
}

export interface InDemandSkillsParams {
  org_id?: number;
  limit?: number;
  offset?: number;
}

export interface SuggestedSkillParams {
  employee_id: string;
  skill_id: number;
}

export interface CareerPathParams {
  discipline_id: number;
}

export interface RoleParams {
  role_id: string;
}

export interface RoleDetailsParams {
  role_id: string;
  hrbp?: boolean;
  onSuccess?: (data: Job) => void;
}

export interface OpenReqsParams {
  role_id: string;
  limit?: number;
  offset?: number;
}

export interface JobParams {
  job_id: number;
}

export interface EmployeesToConnectParams {
  job_id?: number;
  state_id?: number;
  country_id?: number;
  manager_id?: string;
  my_team_only?: boolean;
  limit?: number;
  offset?: number;
}

export interface SkillEmployeesParams {
  skill_id: string;
  state_id?: number | null;
  country_id?: number | null;
  manager_id?: string | null;
}

export interface EditableSkillsParams {
  inferred?: boolean;
  limit?: number;
  offset?: number;
  scope?: BuilderScope;
  sort_by?: SkillSort | null;
  direction?: SkillSortDirections | null;
  inferences_only?: boolean | null;
  forced?: boolean | null;
}

export interface JobStatusParams {
  id: number;
  timestamp?: number | null;
}

export interface DataStatusParams {
  timestamp?: number | null;
}

export interface NotificationsParams extends PaginationParams {
  timestamp?: number;
}

export interface AddSkillParams {
  skill_id: number;
  level: SkillLevel;
  is_opt_in_mentor?: boolean;
  is_target?: boolean;
  skill?: Skill;
  source: SkillUpdateSource;
  source_id?: number;
}
export interface UpdateSkillParams extends AddSkillParams {
  apolloCache: ApolloCache<object>;
  reason?: 'level' | 'mentoring';
  onSuccess?: () => void;
}

export interface UpdateSkillsParams {
  apolloCache: ApolloCache<object>;
  onSuccess?: () => void;
  is_onboarding?: boolean;
  onboarding_step?: BuilderStep;
  levels: SkillLevelData[];
}

export interface UpdateTargetSkillParams {
  skill_id: number;
  is_target?: boolean;
  targetSkill?: Skill;
}

export interface UpdateTargetSkillsParams {
  onSuccess: () => void;
  onboarding_step: BuilderStep;
  targets: number[];
}

export interface UpdateCourseParams {
  skill_id: number;
  course_ids: number[];
}

export interface UpdateTargetRoleParams {
  role_id: string;
  is_target: boolean;
  limitReached?: boolean; // response parameter – is set to `true` if the target jobs limit has been reached
}

export interface CoCParams {
  org_id?: number | null;
}

export interface UpdatePreferencesParams {
  skills_profile_is_public?: boolean;
  open_to_opportunities?: boolean;
  my_manager_can_view_my_target_job?: boolean;
  mentor_others?: boolean;
  skills_i_mentor?: number[];
  email_notifications?: EmailNotificationType[];
  onboarding_step?: string;
  onSuccess?: () => void;
}

export type UpdateSettingsParams = Record<string, string | number | boolean | null>;

export interface UpdateDataStatusParams {
  force?: boolean;
}

// TODO: migrate DataContext to TypeScript

export interface IDataState {
  // USER
  preferences: ContextObject<Preferences, {}>;
  settings: ContextObject<Settings, {}>;
  notifications: ContextEntityWithCount<INotification, NotificationsParams>;
  coc: ContextEntity<Manager, CoCParams>;
  unreadCount: ContextObject<UnreadCountObject, {}>;
  talentData: ContextObject<TalentDataObject, RoleParams>;
  requireNotifications?: (params?: NotificationsParams) => void;
  requirePreferences?: () => void;
  requireSettings?: () => void;
  requireCoC?: (params?: CoCParams) => void;
  requireUnreadCount?: () => void;
  requireTalentData?: (params: RoleParams) => void;
  preferencesUpdate: ActionState<UpdatePreferencesParams>;
  settingsUpdate: ActionState<UpdateSettingsParams>;
  updatePreferences?: (params: UpdatePreferencesParams) => void;
  updateSettings?: (params: UpdateSettingsParams) => void;

  // SKILLS
  boardSkills: ContextObject<BoardSkills, {}>;
  boardSuggested: ContextObject<BoardSuggested, {}>;
  boardJobs: ContextObject<BoardJobs, {}>;
  recommendedSkills: ContextEntity<Skill, {}>;
  targetSkills: ContextEntity<Skill, {}>;
  learningProgress: ContextEntity<Skill, LearningProgressParams>;
  skillsGap: ContextObject<Job, {}>;
  jobsIndex: ContextEntityWithCount<Job, JobsIndexParams>;
  mentorship: ContextEntity<Skill, {}>;
  skillsInDemand: ContextEntityWithCount<Skill, InDemandSkillsParams>;
  requireBoardSkills?: () => void;
  requireBoardSuggested?: () => void;
  requireBoardJobs?: () => void;
  requireRecommendedSkills?: () => void;
  requireTargetSkills?: () => void;
  requireLearningProgress?: (params: LearningProgressParams) => void;
  requireSkillsGap?: () => void;
  requireJobsIndex?: (params: JobsIndexParams) => void;
  requireMentorship?: () => void;
  requireInDemandSkills?: (params: InDemandSkillsParams) => void;
  // supervisor actions:
  suggestedSkillAdd: ActionState<SuggestedSkillParams>;
  suggestedSkillRemove: ActionState<SuggestedSkillParams>;
  addSuggestedSkill?: (params: SuggestedSkillParams) => void;
  removeSuggestedSkill?: (params: SuggestedSkillParams) => void;

  // SKILL
  skill: ContextObject<Skill, SkillDetailsParams>;
  devPlan: ContextObject<DevPlan, DevPlanParams>;
  skillEmployees: ContextObject<SkillEmployees, SkillEmployeesParams>;
  requireEmployeeSkill?: (params: SkillDetailsParams) => void;
  requireDevPlan?: (params: DevPlanParams) => void;
  requireSkillEmployees?: (params: SkillEmployeesParams) => void;
  skillAdd: ActionState<AddSkillParams>;
  skillUpdate: ActionState<UpdateSkillParams>;
  targetSkillUpdate: ActionState<UpdateTargetSkillParams>;
  courseUpdate: ActionState<UpdateCourseParams>;
  addSkill?: (params: AddSkillParams) => void;
  updateSkill?: (params: UpdateSkillParams) => void;
  updateTargetSkill?: (params: UpdateTargetSkillParams) => void;
  updateCourse?: (params: UpdateCourseParams) => void;

  // ROLE
  role: ContextObject<Job, RoleDetailsParams>;
  matchRate: ContextObject<MatchRateObject, RoleParams>;
  employeesToConnect: ContextEntityWithCount<Employee, EmployeesToConnectParams>;
  jobMoves: ContextObject<JobMoves, JobParams>;
  targetRole: ContextEntity<Job, {}>;
  careerPath: ContextEntity<CareerPath, CareerPathParams>;
  openReqs: ContextEntityWithCount<OpenReq, OpenReqsParams>;
  requireEmployeeRole?: (params: RoleDetailsParams) => void;
  requireMatchRate?: (params: RoleParams) => void;
  requireEmployeesToConnect?: (params: EmployeesToConnectParams) => void;
  requireJobMoves?: (params: JobParams) => void;
  requireTargetRole?: () => void;
  requireCareerPath?: (params: CareerPathParams) => void;
  requireOpenReqs?: (params: OpenReqsParams) => void;
  targetRoleUpdate: ActionState<UpdateTargetRoleParams>;
  updateTargetRole?: (params: UpdateTargetRoleParams) => void;

  // EDITABLE SKILLS
  skl: ContextObject<Skill, SkillParams>;
  editableSks: ContextEntityWithCount<Skill, EditableSkillsParams>;
  requireSkl?: (params: SkillParams) => void;
  requireEditableSkills?: (params: EditableSkillsParams) => void;
  skillsUpdate: ActionState<UpdateSkillsParams>;
  targetSkillsUpdate: ActionState<UpdateTargetSkillsParams>;
  updateSkills?: (params: UpdateSkillsParams) => void;
  updateTargetSkills?: (params: UpdateTargetSkillsParams) => void;

  // DATA STATUS
  jobStatus: ContextObject<JobStatusObject, JobStatusParams>;
  dataStatus: ContextObject<DataStatusObject, DataStatusParams>;
  dataStatusUpdate: ActionState<UpdateDataStatusParams>;
  requireJobStatus?: (params: JobStatusParams) => void;
  requireDataStatus?: (params: DataStatusParams) => void;
  updateDataStatus?: (params?: UpdateDataStatusParams) => void;
}

// USER
// User Preferences
export const PREFERENCES_FETCH = 'PREFERENCES' as const;
export const PREFERENCES_FETCHING = `${PREFERENCES_FETCH}${FETCHING}` as const;
export const PREFERENCES_FETCHED = `${PREFERENCES_FETCH}${FETCHED}` as const;
export const PREFERENCES_PARAMS = `${PREFERENCES_FETCH}${PARAMS}` as const;
// User Settings
export const SETTINGS_FETCH = 'SETTINGS' as const;
export const SETTINGS_FETCHING = `${SETTINGS_FETCH}${FETCHING}` as const;
export const SETTINGS_FETCHED = `${SETTINGS_FETCH}${FETCHED}` as const;
export const SETTINGS_PARAMS = `${SETTINGS_FETCH}${PARAMS}` as const;
// Notifications
export const NOTIFICATIONS_FETCH = 'NOTIFICATIONS' as const;
export const NOTIFICATIONS_FETCHING = `${NOTIFICATIONS_FETCH}${FETCHING}` as const;
export const NOTIFICATIONS_FETCHED = `${NOTIFICATIONS_FETCH}${FETCHED}` as const;
export const NOTIFICATIONS_PARAMS = `${NOTIFICATIONS_FETCH}${PARAMS}` as const;
// Notification Counts
export const UNREAD_COUNT_FETCH = 'UNREAD_COUNT' as const;
export const UNREAD_COUNT_FETCHING = `${UNREAD_COUNT_FETCH}${FETCHING}` as const;
export const UNREAD_COUNT_FETCHED = `${UNREAD_COUNT_FETCH}${FETCHED}` as const;
export const UNREAD_COUNT_PARAMS = `${UNREAD_COUNT_FETCH}${PARAMS}` as const;
// Chain of Command
export const COC_FETCH = 'COC' as const;
export const COC_FETCHING = `${COC_FETCH}${FETCHING}` as const;
export const COC_FETCHED = `${COC_FETCH}${FETCHED}` as const;
export const COC_PARAMS = `${COC_FETCH}${PARAMS}` as const;
// Preferences update
export const PREFERENCES_UPDATING = 'PREFERENCES_UPDATING' as const;
export const PREFERENCES_UPDATED = 'PREFERENCES_UPDATED' as const;
// Settings update
export const SETTINGS_UPDATING = 'SETTINGS_UPDATING' as const;
export const SETTINGS_UPDATED = 'SETTINGS_UPDATED' as const;
// Talent Data
export const TALENT_DATA_FETCH = 'TALENT_DATA' as const;
export const TALENT_DATA_FETCHING = `${TALENT_DATA_FETCH}${FETCHING}` as const;
export const TALENT_DATA_FETCHED = `${TALENT_DATA_FETCH}${FETCHED}` as const;
export const TALENT_DATA_PARAMS = `${TALENT_DATA_FETCH}${PARAMS}` as const;

// SKILLS
// Employee Dashboard
export const EMPLOYEE_BOARD_SKILLS_FETCH = 'EMPLOYEE_BOARD_SKILLS' as const;
export const EMPLOYEE_BOARD_SKILLS_FETCHING = `${EMPLOYEE_BOARD_SKILLS_FETCH}${FETCHING}` as const;
export const EMPLOYEE_BOARD_SKILLS_FETCHED = `${EMPLOYEE_BOARD_SKILLS_FETCH}${FETCHED}` as const;
export const EMPLOYEE_BOARD_SKILLS_PARAMS = `${EMPLOYEE_BOARD_SKILLS_FETCH}${PARAMS}` as const;
export const EMPLOYEE_BOARD_SUGGESTED_FETCH = 'EMPLOYEE_BOARD_SUGGESTED' as const;
export const EMPLOYEE_BOARD_SUGGESTED_FETCHING = `${EMPLOYEE_BOARD_SUGGESTED_FETCH}${FETCHING}` as const;
export const EMPLOYEE_BOARD_SUGGESTED_FETCHED = `${EMPLOYEE_BOARD_SUGGESTED_FETCH}${FETCHED}` as const;
export const EMPLOYEE_BOARD_SUGGESTED_PARAMS = `${EMPLOYEE_BOARD_SUGGESTED_FETCH}${PARAMS}` as const;
export const EMPLOYEE_BOARD_JOBS_FETCH = 'EMPLOYEE_BOARD_JOBS' as const;
export const EMPLOYEE_BOARD_JOBS_FETCHING = `${EMPLOYEE_BOARD_JOBS_FETCH}${FETCHING}` as const;
export const EMPLOYEE_BOARD_JOBS_FETCHED = `${EMPLOYEE_BOARD_JOBS_FETCH}${FETCHED}` as const;
export const EMPLOYEE_BOARD_JOBS_PARAMS = `${EMPLOYEE_BOARD_JOBS_FETCH}${PARAMS}` as const;
// In Demand Skills
export const IN_DEMAND_SKILLS_FETCH = 'IN_DEMAND_SKILLS' as const;
export const IN_DEMAND_SKILLS_FETCHING = `${IN_DEMAND_SKILLS_FETCH}${FETCHING}` as const;
export const IN_DEMAND_SKILLS_FETCHED = `${IN_DEMAND_SKILLS_FETCH}${FETCHED}` as const;
export const IN_DEMAND_SKILLS_PARAMS = `${IN_DEMAND_SKILLS_FETCH}${PARAMS}` as const;
// Recommended Skills
export const RECOMMENDED_SKILLS_FETCH = 'RECOMMENDED_SKILLS' as const;
export const RECOMMENDED_SKILLS_FETCHING = `${RECOMMENDED_SKILLS_FETCH}${FETCHING}` as const;
export const RECOMMENDED_SKILLS_FETCHED = `${RECOMMENDED_SKILLS_FETCH}${FETCHED}` as const;
export const RECOMMENDED_SKILLS_PARAMS = `${RECOMMENDED_SKILLS_FETCH}${PARAMS}` as const;
// Targeted Skills
export const TARGETED_SKILLS_FETCH = 'TARGETED_SKILLS' as const;
export const TARGETED_SKILLS_FETCHING = `${TARGETED_SKILLS_FETCH}${FETCHING}` as const;
export const TARGETED_SKILLS_FETCHED = `${TARGETED_SKILLS_FETCH}${FETCHED}` as const;
export const TARGETED_SKILLS_PARAMS = `${TARGETED_SKILLS_FETCH}${PARAMS}` as const;
// Learning Progress
export const LEARNING_PROGRESS_FETCH = 'LEARNING_PROGRESS' as const;
export const LEARNING_PROGRESS_FETCHING = `${LEARNING_PROGRESS_FETCH}${FETCHING}` as const;
export const LEARNING_PROGRESS_FETCHED = `${LEARNING_PROGRESS_FETCH}${FETCHED}` as const;
export const LEARNING_PROGRESS_PARAMS = `${LEARNING_PROGRESS_FETCH}${PARAMS}` as const;
// Recommended Skills
export const SKILLS_GAP_FETCH = 'SKILLS_GAP' as const;
export const SKILLS_GAP_FETCHING = `${SKILLS_GAP_FETCH}${FETCHING}` as const;
export const SKILLS_GAP_FETCHED = `${SKILLS_GAP_FETCH}${FETCHED}` as const;
export const SKILLS_GAP_PARAMS = `${SKILLS_GAP_FETCH}${PARAMS}` as const;
// Jobs Index
export const JOBS_INDEX_FETCH = 'JOBS_INDEX' as const;
export const JOBS_INDEX_FETCHING = `${JOBS_INDEX_FETCH}${FETCHING}` as const;
export const JOBS_INDEX_FETCHED = `${JOBS_INDEX_FETCH}${FETCHED}` as const;
export const JOBS_INDEX_PARAMS = `${JOBS_INDEX_FETCH}${PARAMS}` as const;
// Mentorship
export const MENTORSHIP_FETCH = 'MENTORSHIP' as const;
export const MENTORSHIP_FETCHING = `${MENTORSHIP_FETCH}${FETCHING}` as const;
export const MENTORSHIP_FETCHED = `${MENTORSHIP_FETCH}${FETCHED}` as const;
export const MENTORSHIP_PARAMS = `${MENTORSHIP_FETCH}${PARAMS}` as const;
// SUPV: Suggested skills
export const SUGGESTED_SKILL_ADDING = 'SUGGESTED_SKILL_ADDING' as const;
export const SUGGESTED_SKILL_ADDED = 'SUGGESTED_SKILL_ADDED' as const;
export const SUGGESTED_SKILL_REMOVING = 'SUGGESTED_SKILL_REMOVING' as const;
export const SUGGESTED_SKILL_REMOVED = 'SUGGESTED_SKILL_REMOVED' as const;

// SKILL
// Employee Skill
export const EMPLOYEE_SKILL_FETCH = 'EMPLOYEE_SKILL' as const;
export const EMPLOYEE_SKILL_FETCHING = `${EMPLOYEE_SKILL_FETCH}${FETCHING}` as const;
export const EMPLOYEE_SKILL_FETCHED = `${EMPLOYEE_SKILL_FETCH}${FETCHED}` as const;
export const EMPLOYEE_SKILL_PARAMS = `${EMPLOYEE_SKILL_FETCH}${PARAMS}` as const;
// Employee Skill Dev Plan
export const DEV_PLAN_FETCH = 'DEV_PLAN' as const;
export const DEV_PLAN_FETCHING = `${DEV_PLAN_FETCH}${FETCHING}` as const;
export const DEV_PLAN_FETCHED = `${DEV_PLAN_FETCH}${FETCHED}` as const;
export const DEV_PLAN_PARAMS = `${DEV_PLAN_FETCH}${PARAMS}` as const;
// Skill Employees
export const SKILL_EMPLOYEES_FETCH = 'SKILL_EMPLOYEES' as const;
export const SKILL_EMPLOYEES_FETCHING = `${SKILL_EMPLOYEES_FETCH}${FETCHING}` as const;
export const SKILL_EMPLOYEES_FETCHED = `${SKILL_EMPLOYEES_FETCH}${FETCHED}` as const;
export const SKILL_EMPLOYEES_PARAMS = `${SKILL_EMPLOYEES_FETCH}${PARAMS}` as const;
// Skill Update
export const SKILL_UPDATING = 'SKILL_UPDATING' as const;
export const SKILL_UPDATED = 'SKILL_UPDATED' as const;
// Skill Add (clone of Skill Update – just to track Skill Addition independently)
export const SKILL_ADDING = 'SKILL_ADDING' as const;
export const SKILL_ADDED = 'SKILL_ADDED' as const;
// Target Skill Update
export const TARGET_SKILL_UPDATING = 'TARGET_SKILL_UPDATING' as const;
export const TARGET_SKILL_UPDATED = 'TARGET_SKILL_UPDATED' as const;
// Course Update
export const COURSE_UPDATING = 'COURSE_UPDATING' as const;
export const COURSE_UPDATED = 'COURSE_UPDATED' as const;

// ROLE
// Employee Role
export const EMPLOYEE_ROLE_FETCH = 'EMPLOYEE_ROLE' as const;
export const EMPLOYEE_ROLE_FETCHING = `${EMPLOYEE_ROLE_FETCH}${FETCHING}` as const;
export const EMPLOYEE_ROLE_FETCHED = `${EMPLOYEE_ROLE_FETCH}${FETCHED}` as const;
export const EMPLOYEE_ROLE_PARAMS = `${EMPLOYEE_ROLE_FETCH}${PARAMS}` as const;
export const TARGET_ROLE_UPDATING = 'TARGET_ROLE_UPDATING' as const;
// Employee Role Match Rate
export const EMPLOYEE_MATCH_RATE_FETCH = 'EMPLOYEE_MATCH_RATE' as const;
export const EMPLOYEE_MATCH_RATE_FETCHING = `${EMPLOYEE_MATCH_RATE_FETCH}${FETCHING}` as const;
export const EMPLOYEE_MATCH_RATE_FETCHED = `${EMPLOYEE_MATCH_RATE_FETCH}${FETCHED}` as const;
export const EMPLOYEE_MATCH_RATE_PARAMS = `${EMPLOYEE_MATCH_RATE_FETCH}${PARAMS}` as const;
// Employees to connect
export const EMPLOYEES_CONNECT_FETCH = 'EMPLOYEES_CONNECT' as const;
export const EMPLOYEES_CONNECT_FETCHING = `${EMPLOYEES_CONNECT_FETCH}${FETCHING}` as const;
export const EMPLOYEES_CONNECT_FETCHED = `${EMPLOYEES_CONNECT_FETCH}${FETCHED}` as const;
export const EMPLOYEES_CONNECT_PARAMS = `${EMPLOYEES_CONNECT_FETCH}${PARAMS}` as const;
// Technical Career Path
export const TECHNICAL_CAREER_PATH_FETCH = 'TECHNICAL_CAREER_PATH' as const;
export const TECHNICAL_CAREER_PATH_FETCHING = `${TECHNICAL_CAREER_PATH_FETCH}${FETCHING}` as const;
export const TECHNICAL_CAREER_PATH_FETCHED = `${TECHNICAL_CAREER_PATH_FETCH}${FETCHED}` as const;
export const TECHNICAL_CAREER_PATH_PARAMS = `${TECHNICAL_CAREER_PATH_FETCH}${PARAMS}` as const;
// Most Common Job Moves
export const JOB_MOVES_FETCH = 'JOB_MOVES' as const;
export const JOB_MOVES_FETCHING = `${JOB_MOVES_FETCH}${FETCHING}` as const;
export const JOB_MOVES_FETCHED = `${JOB_MOVES_FETCH}${FETCHED}` as const;
export const JOB_MOVES_PARAMS = `${JOB_MOVES_FETCH}${PARAMS}` as const;
// Employee Target Role
export const TARGET_ROLE_FETCH = 'TARGET_ROLE' as const;
export const TARGET_ROLE_FETCHING = `${TARGET_ROLE_FETCH}${FETCHING}` as const;
export const TARGET_ROLE_FETCHED = `${TARGET_ROLE_FETCH}${FETCHED}` as const;
export const TARGET_ROLE_PARAMS = `${TARGET_ROLE_FETCH}${PARAMS}` as const;
export const TARGET_ROLE_UPDATED = 'TARGET_ROLE_UPDATED' as const;
// Employee Role Match Rate
export const OPEN_REQS_FETCH = 'OPEN_REQS' as const;
export const OPEN_REQS_FETCHING = `${OPEN_REQS_FETCH}${FETCHING}` as const;
export const OPEN_REQS_FETCHED = `${OPEN_REQS_FETCH}${FETCHED}` as const;
export const OPEN_REQS_PARAMS = `${OPEN_REQS_FETCH}${PARAMS}` as const;

// EDITABLE SKILLS
// Skill
export const SKL_FETCH = 'SKL' as const;
export const SKL_FETCHING = `${SKL_FETCH}${FETCHING}` as const;
export const SKL_FETCHED = `${SKL_FETCH}${FETCHED}` as const;
export const SKL_PARAMS = `${SKL_FETCH}${PARAMS}` as const;
// Editable Skills
export const MY_SKILLS_FETCH = 'MY_SKILLS' as const;
export const MY_SKILLS_FETCHING = `${MY_SKILLS_FETCH}${FETCHING}` as const;
export const MY_SKILLS_FETCHED = `${MY_SKILLS_FETCH}${FETCHED}` as const;
export const MY_SKILLS_PARAMS = `${MY_SKILLS_FETCH}${PARAMS}` as const;
export const MY_SKILLS_UPDATING = 'MY_SKILLS_UPDATING' as const;
export const MY_SKILLS_UPDATED = 'MY_SKILLS_UPDATED' as const;
export const MY_TARGET_SKILLS_UPDATING = 'MY_TARGET_SKILLS_UPDATING' as const;
export const MY_TARGET_SKILLS_UPDATED = 'MY_TARGET_SKILLS_UPDATED' as const;

// DATA STATUS
export const JOB_STATUS_FETCH = 'JOB_STATUS' as const;
export const JOB_STATUS_FETCHING = `${JOB_STATUS_FETCH}${FETCHING}` as const;
export const JOB_STATUS_FETCHED = `${JOB_STATUS_FETCH}${FETCHED}` as const;
export const JOB_STATUS_PARAMS = `${JOB_STATUS_FETCH}${PARAMS}` as const;
export const DATA_STATUS_FETCH = 'DATA_STATUS' as const;
export const DATA_STATUS_FETCHING = `${DATA_STATUS_FETCH}${FETCHING}` as const;
export const DATA_STATUS_FETCHED = `${DATA_STATUS_FETCH}${FETCHED}` as const;
export const DATA_STATUS_PARAMS = `${DATA_STATUS_FETCH}${PARAMS}` as const;
export const DATA_STATUS_UPDATING = 'DATA_STATUS_UPDATING' as const;
export const DATA_STATUS_UPDATED = 'DATA_STATUS_UPDATED' as const;

export type DataActions =
  // TODO: define all actions
  | { type: typeof JOB_STATUS_FETCHING; payload?: null; params: JobStatusParams; }
  | { type: typeof JOB_STATUS_FETCHED; payload: JobStatusObject | null; params: JobStatusParams; }
  | { type: typeof JOB_STATUS_PARAMS; params: JobStatusParams; }
  | { type: typeof DATA_STATUS_FETCHING; payload?: null; params: DataStatusParams; }
  | { type: typeof DATA_STATUS_FETCHED; payload: DataStatusObject | null; params: DataStatusParams; }
  | { type: typeof DATA_STATUS_PARAMS; params: DataStatusParams; }
  | { type: typeof DATA_STATUS_UPDATING; payload?: null; params: UpdateDataStatusParams; }
  | { type: typeof DATA_STATUS_UPDATED; payload: boolean; params: UpdateDataStatusParams; }
  | { type: typeof SUGGESTED_SKILL_ADDING; payload?: null; params: SuggestedSkillParams; }
  | { type: typeof SUGGESTED_SKILL_ADDED; payload: boolean; params: SuggestedSkillParams; }
  | { type: typeof SUGGESTED_SKILL_REMOVING; payload?: null; params: SuggestedSkillParams; }
  | { type: typeof SUGGESTED_SKILL_REMOVED; payload: boolean; params: SuggestedSkillParams; }
  | CommonActions;

import map from 'lodash/map';
import size from 'lodash/size';
import take from 'lodash/take';
import isArray from 'lodash/isArray';
import reverse from 'lodash/reverse';
import toSafeInteger from 'lodash/toSafeInteger';
import toFinite from 'lodash/toFinite';
import round from 'lodash/round';
import toLower from 'lodash/toLower';
import startCase from 'lodash/startCase';
// local imports
import { Job } from '../models/job';
import { Skill } from '../models/skill';
import { BoardSuggested, BoardJobs } from '../models/board';
import { MatchRateObject } from '../models/matchRateObject';
import { Manager, ManagerOriginal } from '../models/manager';
import { BOARD_MAX_JOBS, BOARD_MAX_SKILLS } from '../config/params';
import { sanitizeRoleLevel, sanitizeSkillLevel } from '../helpers/models';

// ATTENTION! Transformation Functions must NOT mutate the objects!

// USER

export const transformCoC = (managers: ManagerOriginal[]) => reverse(map(managers, ({ code, full_name }, index) => ({
  id: code,
  title: startCase(toLower(full_name)),
  level: size(managers) - toSafeInteger(index) - 1
} as Manager)));

// ROLE

export const transformRoles = (roles: Job[] | null) => map(roles, sanitizeRoleLevel) as Job[];

// SKILLS

export const transformBoardSuggested = (suggested_skills: BoardSuggested): BoardSuggested => ({
  ...suggested_skills || {},
  top: take(suggested_skills.top, BOARD_MAX_SKILLS)
});

export const transformBoardJobs = (close_match_jobs: BoardJobs): BoardJobs => ({
  ...close_match_jobs || {},
  top: take(close_match_jobs.top, BOARD_MAX_JOBS)
});

// const skillCompareFunc = ({ level, title }) => `${toSafeInteger(999 - level)}-${toLower(title)}`;
// const sortSkills = (skills) => sortBy(skills, skillCompareFunc);
export const transformSkills = (results: Skill[]) => map(results, sanitizeSkillLevel) as Skill[];


// SKILL

export const transformSkill = sanitizeSkillLevel;


// EDITABLE SKILLS

export const transformEditableSks = (editableSks?: Skill[] | null) => isArray(editableSks)
  ? map(editableSks, sanitizeSkillLevel) as Skill[]
  : editableSks || null;

export const transformMatchRate = ({ match_rate }: MatchRateObject) => ({
  match_rate: round(toFinite(match_rate))
} as MatchRateObject);

import { memo, useEffect, useMemo, useState, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import toSafeInteger from 'lodash/toSafeInteger';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import { useLazyQuery } from '@apollo/client';
// Material UI imports
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
// EmPath UI Components
import { fontWeightBold } from '@empathco/ui-components/src/styles/themeOptions';
import { uniformDateOptions } from '@empathco/ui-components/src/common/intl';
import { getJsDateFromISO } from '@empathco/ui-components/src/helpers/datetime';
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import DataTable from '@empathco/ui-components/src/elements/DataTable';
// local imports
import { ADMIN_USER_ENGAGEMENT_QUERY } from '../graphql/AdminReportUserEngagement';
import { ADMIN_JOB_TRENDS_QUERY } from '../graphql/AdminReportJobTrends';
import {
  AdminReportType,
  AdminReportJobTrends, AdminReportJobTrendsDocument, AdminReportJobTrendsQueryVariables,
  AdminReportUserEngagement, AdminReportUserEngagementDocument, AdminReportUserEngagementQueryVariables
} from '../graphql/types';
import { ADMIN_TABLE_PAGE_SIZES } from '../config/params';
import PaginationControls, { hasPagination } from '../v3/PaginationControls';
// SCSS imports
import { overlayDefault } from '@empathco/ui-components/src/styles/modules/Overlay.module.scss';

type AdminReportProps = {
  reportId: number;
  reportType: AdminReportType;
  // for Storybook only
  testPending?: boolean;
}

const AdminReportPropTypes = {
  reportId: PropTypes.number.isRequired,
  reportType: PropTypes.string.isRequired as Validator<AdminReportType>,
  testPending: PropTypes.bool
};

// eslint-disable-next-line complexity
const AdminReport: FunctionComponent<AdminReportProps> = ({
  reportId,
  reportType,
  testPending
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();

  // lazy load user engagement report
  const {
    query: getUserEngagement, pending: pendingUserEngagement, failed: failedUserEngagement,
    count: countUserEngagement, data: dataUserEngagement, results: userEngagement, variables: variablesUserEngagement
  } = useQueryCounted({
    data: undefined as unknown as AdminReportUserEngagement,
    key: 'adminReportUserEngagement',
    lazyQuery: useLazyQuery(ADMIN_USER_ENGAGEMENT_QUERY as typeof AdminReportUserEngagementDocument)
  });

  // lazy load user engagement report
  const {
    query: getJobTrends, pending: pendingJobTrends, failed: failedJobTrends,
    count: countJobTrends, results: jobTrends, variables: variablesJobTrends
  } = useQueryCounted({
    data: undefined as unknown as AdminReportJobTrends,
    key: 'adminReportJobTrends',
    lazyQuery: useLazyQuery(ADMIN_JOB_TRENDS_QUERY as typeof AdminReportJobTrendsDocument)
  });

  const data = (reportType === AdminReportType.user_engagement && userEngagement) ||
    (reportType === AdminReportType.job_trends && jobTrends) || 
    undefined;
  const count = (reportType === AdminReportType.user_engagement && countUserEngagement) ||
    (reportType === AdminReportType.job_trends && countJobTrends) ||
    0;
  const prevVars = (reportType === AdminReportType.user_engagement && variablesUserEngagement) ||
    (reportType === AdminReportType.job_trends && variablesJobTrends) ||
    undefined;
  const pending = (reportType === AdminReportType.user_engagement && pendingUserEngagement) ||
    (reportType === AdminReportType.job_trends && pendingJobTrends) ||
    testPending;
  const failed = (reportType === AdminReportType.user_engagement && failedUserEngagement) ||
    (reportType === AdminReportType.job_trends && failedJobTrends) ||
    undefined;

  const loading = pending && !data;
  const reloading = Boolean(pending && data);
    
  const titles = useMemo(() =>
    // User Engagement Report
    (reportType === AdminReportType.user_engagement && [
      'admin.report.user_engagement.day',
      'admin.report.user_engagement.created',
      'admin.report.user_engagement.logged_in'
    ]) ||
    // Job Trends Report
    (reportType === AdminReportType.job_trends && [
      'admin.report.job_trends.job_code',
      'admin.report.job_trends.job_title',
      'admin.report.job_trends.employees',
      'admin.report.job_trends.hires',
      'admin.report.job_trends.close_match_employees'
    ]) ||
    // Unknown Report
    ['json'], [reportType]);

  const rows = useMemo(() => [
    ...map(data, (record) => ({
      selected: false,
      values:
      // User Engagement Report
      (reportType === AdminReportType.user_engagement && [
        /* eslint-disable react/jsx-key */
        // eslint-disable-next-line react/jsx-props-no-spreading
        <FormattedDate value={getJsDateFromISO((record as AdminReportUserEngagement).day as string)} {...uniformDateOptions}/>,
        <FormattedNumber value={toSafeInteger((record as AdminReportUserEngagement).created)}/>,
        <FormattedNumber value={toSafeInteger((record as AdminReportUserEngagement).logged_in)}/>
        /* eslint-enable react/jsx-key */
      ]) ||
      // Job Trends Report
      (reportType === AdminReportType.job_trends && [
        (record as AdminReportJobTrends).job_code || '—',
        (record as AdminReportJobTrends).job_title || '—',
        /* eslint-disable react/jsx-key */
        <FormattedNumber value={toSafeInteger((record as AdminReportJobTrends).employees)}/>,
        <FormattedNumber value={toSafeInteger((record as AdminReportJobTrends).hires)}/>,
        <FormattedNumber value={toSafeInteger((record as AdminReportJobTrends).close_match_employees)}/>
        /* eslint-enable react/jsx-key */
      ]) ||
      // Unknown Report
      [JSON.stringify(record, null)]
    })),
    ...reportType === AdminReportType.user_engagement && size(data) >= 1 ? [{
      selected: true,
      values: [
        /* eslint-disable react/jsx-key */
        <Box fontWeight={fontWeightBold} textTransform="uppercase">
          <FormattedMessage id="admin.report.user_engagement.total"/>
        </Box>,
        <Box fontWeight={fontWeightBold}>
          <FormattedNumber value={toSafeInteger(dataUserEngagement?.adminReportUserEngagement?.total_created)}/>
        </Box>,
        <Box fontWeight={fontWeightBold}>
          <FormattedNumber value={toSafeInteger(dataUserEngagement?.adminReportUserEngagement?.total_logged_in)}/>
        </Box>
        /* eslint-enable react/jsx-key */
      ]
    }] : []
  ], [data, dataUserEngagement, reportType]);

  const pagination = (
    <PaginationControls
        settingsId="admin_report"
        loaded={Boolean(data)}
        pending={pending}
        loading={loading}
        total={count}
        currentPage={currentPage}
        pageSizes={ADMIN_TABLE_PAGE_SIZES}
        onPageSelected={setCurrentPage}
        onPageSize={setPageSize}
        disabled={pending || failed}
        totalMessage="admin.report.pagination"
    />
  );
  const withPagination = hasPagination(pagination);

  useEffect(() => {
    if (reportId && pageSize) {
      const variables: AdminReportUserEngagementQueryVariables | AdminReportJobTrendsQueryVariables = {
        report_id: reportId,
        limit: pageSize
      };
      let curPage = currentPage;
      if (paramsDiffer(prevVars, variables)) {
        curPage = 1;
        setCurrentPage(1);
      }
      variables.offset = pageSize * (curPage - 1);
      (
        (reportType === AdminReportType.user_engagement && getUserEngagement) ||
        (reportType === AdminReportType.job_trends && getJobTrends) ||
        undefined
      )?.({ variables });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // ignoring `prevVars` changes:
    currentPage, pageSize, reportId, reportType, getUserEngagement, getJobTrends
  ]);

  const content = (
    <>
      <CardSection>
        <DataTable
            tableSize="medium"
            titles={titles}
            empty="admin.report.empty"
            data={rows}
            pending={loading}
            failed={failed}
            lastLeftAlignedTitle={
              (reportType === AdminReportType.job_trends && 1) ||
              0
            }
        />
      </CardSection>
      {withPagination ? (
        <CardSection flex bottom>
          {pagination}
        </CardSection>
      ) : pagination}
    </>
  );

  return reloading ? (
    <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        position="relative"
    >
      {content}
      <Box className={overlayDefault}>
        <LinearProgress/>
      </Box>
    </Box>
  ) : content;
};

AdminReport.propTypes = AdminReportPropTypes;

export default memo(AdminReport);

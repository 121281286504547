import { forwardRef, memo, type Component, type MouseEventHandler } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box, { type BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
// Material Icon imports
import CreateIcon from '@mui/icons-material/Create';
// SCSS imports
import { icon, iconDisabled } from './RoleSkillsFilters.module.scss';

type RoleSkillsFilttersProps = {
  admin?: boolean;
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const RoleSkillsFilttersPropTypes = {
  // attributes
  admin: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func as Validator<MouseEventHandler<HTMLButtonElement>>
};

const RoleSkillsFilters = forwardRef<Component<BoxProps>, RoleSkillsFilttersProps>(({
  admin = false,
  disabled = false,
  onClick
}, ref) => (
  <Box
      ref={ref}
      my={-1}
      // display="flex"
      // alignItems="center"
      data-html2canvas-ignore
  >
    <Button
        disableElevation
        onClick={onClick}
        variant="text"
        color="primary"
        startIcon={(
          <Avatar
              variant="rounded"
              className={disabled ? iconDisabled : icon}
          >
            <CreateIcon fontSize="inherit"/>
          </Avatar>
        )}
        disabled={disabled}
    >
      <FormattedMessage id={admin ? 'hr.job_skills.edit' : 'hr.job_skills.view_suggestions'}/>
    </Button>
    {/* TODO: add myself/Employee selector + MatchIndicator */}
  </Box>
));

RoleSkillsFilters.displayName = 'RoleSkillsFilters';

RoleSkillsFilters.propTypes = RoleSkillsFilttersPropTypes;

export default memo(RoleSkillsFilters);
